import { REGISTER_POSITIONS } from '../action-types';
import * as ComponentHelpers from '../helpers/component';
import * as constants from '../constants';

function registerPositions(positions) {
  return {
    type: REGISTER_POSITIONS,
    positions,
  };
}

// Get all positions
export function calculatePositions() {
  return (dispatch, getState) => {
    const stateStore = getState();
    const components = stateStore.components.entities;
    const selections = stateStore.selections.entities;
    const levers = stateStore.levers.entities;
    const isFront = [true, false];
    const positions = [];

    // Components and levers are required
    if (components.length === 0 && levers.length === 0) {
      return;
    }

    // Get positions per component, lever and front/back
    components.forEach((component) => {
      // component.blacklist.available denotes if the component is available,
      // not if the blacklist is.
      if (component.blacklist && !component.blacklist.available) {
        return;
      }

      levers.forEach((lever) => {
        if (lever.lever_type === 'B1') {
          if (component.x_size === 1 && component.y_size === 1) {
            const existingSelection = selections.find(
              (s) => s.lever === lever.pk && s.is_front,
            );
            if (!existingSelection) {
              positions.push({
                entities: [{ x: 0, y: 0 }],
                component: component.pk,
                lever: lever.pk,
                is_front: true,
                direction: constants.NOT_APPLICABLE,
              });
            }
          }
        } else {
          isFront.forEach((is_front) => {
            // Get selections from lever and front
            const currentSelections = selections.filter(
              (selection) =>
                selection.lever === lever.pk && selection.is_front === is_front,
            );

            // Get component blacklist
            const blacklist = ComponentHelpers.getComponentBlacklist(
              component,
              lever.grip,
              is_front,
              lever.lever_type,
            );

            // Add vertical and horizontal, vertical is default
            if (component.has_direction) {
              positions.push({
                entities: ComponentHelpers.getComponentPositions(
                  component.x_size,
                  component.y_size,
                  blacklist,
                  components,
                  currentSelections,
                  is_front,
                  lever,
                  lever.lever_type,
                ),
                component: component.pk,
                lever: lever.pk,
                is_front,
                direction: constants.VERTICAL,
              });
              positions.push({
                entities: ComponentHelpers.getComponentPositions(
                  component.y_size,
                  component.x_size,
                  blacklist,
                  components,
                  currentSelections,
                  is_front,
                  lever,
                  lever.lever_type,
                ),
                component: component.pk,
                lever: lever.pk,
                is_front,
                direction: constants.HORIZONTAL,
              });
              // Add default direction
            } else {
              positions.push({
                entities: ComponentHelpers.getComponentPositions(
                  component.x_size,
                  component.y_size,
                  blacklist,
                  components,
                  currentSelections,
                  is_front,
                  lever,
                  lever.lever_type,
                ),
                component: component.pk,
                lever: lever.pk,
                is_front,
                direction: constants.NOT_APPLICABLE,
              });
            }
          });
        }
      });
    });

    // Register found positions
    dispatch(registerPositions(positions));
  };
}
