import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as constants from '../constants';
import * as EditActions from '../actions/editSelections';
import * as ProjectActions from '../actions/project';
import * as SelectionActions from '../actions/selections';
import * as SelectionHelpers from '../helpers/selection';
import * as Utils from '../utils';
import Button from '../components/forms/Button';
import DocumentLink from '../components/DocumentLink';
import RadioGroup from '../components/forms/RadioGroup';
import Icon from '../components/Icon';
import Label from '../components/forms/Label';
import ToggleDialog from '../components/ToggleDialog';

/**
 * A single selection setting, possibly with multiple options.
 *
 * @param {Object} props - Component props.
 * @param {number|string} props.id - A globally unique setting ID. Required for
 *    `name`, ìd` and `for` attributes.
 * @param {Function} props.onChange - Change event handler.
 * @param {Object} props.availableSetting - Base setting data from the
 *   component.
 * @param {Object} props.activeSetting - The currently saved setting on the
 *   selection.
 */
export function Setting(props) {
  const { id, onChange, availableSetting, activeSetting } = props;
  const type = constants.SETTINGS_STRING[availableSetting.type];
  const className = classnames(
    'selection-setting-group',
    `selection-setting-group--${type}`,
  );

  switch (availableSetting.type) {
    case constants.TEXT:
      return (
        <div className={className}>
          <Label htmlFor={id} text={availableSetting.label} />
          <input
            id={id}
            type={type}
            name={availableSetting.name}
            value={activeSetting.value || ''}
            onChange={onChange}
          />
        </div>
      );

    case constants.RADIO:
      return (
        <RadioGroup
          options={availableSetting.options.map((opt) => ({
            value: opt.ident,
            label: opt.label,
          }))}
          name={`${availableSetting.name}-${id}`}
          groupLabel={`${availableSetting.label}:`}
          groupLabelId={id}
          selected={activeSetting.value || availableSetting.default}
          onChange={onChange}
          className={className}
        />
      );

    default:
      return null;
  }
}
Setting.displayName = 'containers/Selection/Setting';
Setting.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  availableSetting: PropTypes.object.isRequired,
  activeSetting: PropTypes.object,
};
Setting.defaultProps = {
  activeSetting: {},
};

/**
 * Grip selection: a placed component with a position and settings.
 *
 * @param {Object} selection - Selection object.
 * @param {Number} availablePositions - The number of available positions.
 * @param {String} grip - Left or right grip.
 * @param {Boolean} isInteractive - If the selection should be modifiable.
 */
export default class Selection extends React.Component {
  static displayName = 'containers/Selection';

  static propTypes = {
    selection: PropTypes.object.isRequired,
    availablePositions: PropTypes.number.isRequired,
    grip: PropTypes.oneOf([constants.LEFT, constants.RIGHT]),
    isInteractive: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    showFunction: PropTypes.bool,
  };

  static defaultProps = {
    isInteractive: true,
    isHighlighted: false,
    showFunction: false,
    grip: constants.LEFT,
  };

  componentWillUpdate() {
    if (this.refs.toggle) {
      if (
        global.store.getState().project.editingSelection ===
        this.props.selection.pk
      ) {
        this.refs.toggle.open();
      } else if (this.refs.toggle.isOpen()) {
        this.refs.toggle.close();
      }
    }
  }

  /**
   * Register that we're moving, or hinder moving if there are no positions.
   *
   * @param {Object} e - The `dragstart` event.
   */
  handleDragStart = (e) => {
    if (!this.props.availablePositions) {
      e.preventDefault();
      return false;
    }

    global.store.dispatch(
      EditActions.registerMoveStart(this.props.selection.pk),
    );
  };

  /**
   * Register that we've stopped moving
   *
   * @param {Object} e - The `dragend` event.
   */
  handleDragEnd = (e) => {
    e.preventDefault();
    global.store.dispatch(EditActions.registerDragEnd());
  };

  /**
   * Remove a selection.
   */
  handleRemoveClick = () => {
    const hejhej = SelectionActions.deleteSelection(this.props.selection.pk);
    console.log('hejhej: ', hejhej);
    global.store.dispatch(hejhej);
  };

  /**
   * Change a setting value.
   *
   * @param {Object} setting - The setting data.
   * @param {Object} e - The `change` event.
   */
  handleSettingChange = (setting, e) => {
    global.store.dispatch(
      SelectionActions.saveSelectionSetting(
        this.props.selection.pk,
        setting.name,
        e.target.value,
        setting.type === constants.TEXT,
      ),
    );
  };

  /**
   * Set the current selection when settings are opened.
   */
  handleToggleOpen = () => {
    global.store.dispatch(
      ProjectActions.setEditingSelection(this.props.selection.pk),
    );
  };

  /**
   * Reset the current selection when settings are closed.
   */
  handleToggleClose = () => {
    global.store.dispatch(ProjectActions.resetEditingSelection());
  };

  /**
   * Close toggle when clicking close button inside it.
   */
  handleToggleClose = () => {
    this.refs.toggle.close();
    global.store.dispatch(ProjectActions.resetEditingSelection());
  };

  render() {
    const {
      selection,
      grip,
      isInteractive,
      isHighlighted,
      showFunction,
    } = this.props;
    const component = SelectionHelpers.getComponent(selection);

    if (!component) {
      return null;
    }

    const { x_size, y_size } = SelectionHelpers.getSize(selection);
    const gridGroup = SelectionHelpers.getBackGridGroup(selection, grip, selection.lever.lever_type === 'A9');
    const imageUrl = SelectionHelpers.getImageUrl(
      selection,
      grip,
      component.has_left_right,
    );
    const isB1Lever = selection.lever.lever_type === 'B1';

    const className = classnames(
      `grid-selection size-x-${x_size} size-y-${y_size} pos-x-${
        selection.x
      } pos-y-${selection.y}`,
      {
        [`back-group-${gridGroup}`]: gridGroup,
        'grid-selection-highlighted': isHighlighted,
        'grid-selection-b1': isB1Lever,
      },
    );

    let func;
    if (showFunction) {
      let funcText = selection.settings
        .filter((setting) => setting.name === 'function')
        .reduce((str, f) => f.value, '');
      funcText = funcText ? `: ${funcText}` : '';

      func = (
        <span className="grid-selection-function">
          <span>{selection.position_name + funcText}</span>
        </span>
      );
    }

    const settingsBaseClass = 'selection-settings';
    let output;
    
    const content = (
      <>
        <img src={imageUrl} alt={component.name} />
        {func}
      </>
    );

    if (isInteractive) {
      output = (
        <div
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
          draggable="true"
          className={className}
        >
          <ToggleDialog
            key={selection.pk}
            ref="toggle"
            buttonText={content}
            extraClass={settingsBaseClass}
            isNeutral
            onOpen={this.handleToggleOpen}
            onClose={this.handleToggleClose}
            doToggleButtonText={false}
          >
            <div className={`${settingsBaseClass}-body`}>
              <h3 className={`${settingsBaseClass}-title h-plain`}>
                {component.name}
              </h3>
              <Button
                onClick={this.handleToggleClose}
                className={`${settingsBaseClass}-close`}
              >
                <Icon name="cross" />
                <span className="text">{global.gettext('Close')}</span>
              </Button>

              {component.datasheet && (
                <p className={`${settingsBaseClass}-datasheet`}>
                  <DocumentLink url={component.datasheet} />
                </p>
              )}

              {component.settings.map((setting) => (
                <Setting
                  key={`s-${selection.pk}-${setting.name}`}
                  id={`s-${selection.pk}-${setting.name}`}
                  grip={grip}
                  availableSetting={setting}
                  activeSetting={Utils.getByAttribute(
                    selection.settings,
                    'name',
                    setting.name,
                  )}
                  onChange={this.handleSettingChange.bind(this, setting)}
                />
              ))}

              <p className={`${settingsBaseClass}-remove`}>
                <Button onClick={this.handleRemoveClick} isDanger>
                  {global.gettext('Remove component')}
                </Button>
              </p>
            </div>
          </ToggleDialog>
        </div>
      );
    } else {
      output = (
        <div className={className}>
          {content}
        </div>
      );
    }

    return output;
  }
}
