import {
  LOAD_LEVERS,
  LOAD_LEVER_CAN_SETTINGS,
  CHANGE_CABLE_OUT_LENGTH,
  CHANGE_LEVER_CAN_SETTINGS,
  RECEIVE_LEVERS,
  RECEIVE_LEVER,
  RECEIVE_LEVER_CAN_SETTINGS,
  CHANGE_LABEL,
  ADD_ACCESSORY,
  REMOVE_ACCESSORY,
  CLEAR_ACCESSORIES,
  RECEIVE_LEVERS_ERROR,
  RECEIVE_LEVER_CAN_SETTINGS_ERROR,
  CHANGE_COLOR,
  ACTIVATE_LEVER,
  DEACTIVATE_LEVER,
  ACTIVATE_LEVER_BASE,
  DEACTIVATE_LEVER_BASE,
  ACTIVATE_LEVER_HAND_REST,
  DEACTIVATE_LEVER_HAND_REST,
  INIT_MIRROR_LEVER,
  MIRROR_LEVER,
  CANCEL_RESET_MIRROR,
  INIT_RESET_LEVER,
  RESET_LEVER,
  CANCEL_RESET_LEVER,
  SET_LEVER_TYPE,
  ACTIVATE_HEAT,
  DEACTIVATE_HEAT,
  ACTIVATE_HAPTIC_FEEDBACK,
  DEACTIVATE_HAPTIC_FEEDBACK,
} from '../action-types';
import * as constants from '../constants';
import { assign, tuplesToOptions } from '../utils';

export const initialEntityState = {
  pk: null,
  accessories: [],
  cable_length_error: null,
  cable_length: 1000,
  cables: [],
  can_settings: {},
  color: constants.BLACK,
  grip: constants.LEFT,
  has_base: false,
  has_can: false,
  has_hand_rest: true,
  is_active: true,
  label: constants.BLANK,
  resetting: {},
  lever_type: constants.L8,
  has_heat: false,
  has_haptic_feedback: false,
};

function entity(state = initialEntityState, action) {
  // Most actions must have action.id matching the pk
  if (
    action.type !== CHANGE_COLOR &&
    action.type !== RECEIVE_LEVER &&
    state.pk !== action.id
  ) {
    return state;
  }
  // The RECEIVE_LEVER has action.lever.pk that must match
  if (action.type === RECEIVE_LEVER && state.pk !== action.lever.pk) {
    return state;
  }

  switch (action.type) {
    case SET_LEVER_TYPE:
      console.log('=== REDUCER: SET_LEVER_TYPE ===');
      console.log('Previous state:', state);
      console.log('Action:', action);
      
      const newState = {
        ...state,
        entities: state.entities.map(lever => ({
          ...lever,
          lever_type: action.leverType
        }))
      };
      
      console.log('New state:', newState);
      return newState;

    // Receive a lever
    case RECEIVE_LEVER:
      return assign(state, action.lever);

    // Set new cable length and possible error
    case CHANGE_CABLE_OUT_LENGTH:
      return assign(state, {
        cable_length: action.length,
        cable_length_error: action.error,
      });

    // Set new CAN setings
    case CHANGE_LEVER_CAN_SETTINGS:
      return assign(state, {
        can_settings: assign(state.can_settings, action.settings),
      });

    // Set new label choice
    case CHANGE_LABEL:
      return assign(state, {
        label: action.label,
      });

    // Set new lever color
    case CHANGE_COLOR:
      return assign(state, {
        color: action.color,
      });

    // Add new accessory
    case ADD_ACCESSORY:
      return assign(state, {
        accessories: [...state.accessories, action.accessoryId],
      });

    // Remove one accessory
    case REMOVE_ACCESSORY:
      return assign(state, {
        accessories: state.accessories.filter(
          (accessory) => accessory !== action.accessoryId,
        ),
      });

    // Clear all accessories
    case CLEAR_ACCESSORIES:
      return assign(state, {
        accessories: [],
      });

    // Activate lever
    case ACTIVATE_LEVER:
      return assign(state, {
        is_active: true,
      });

    // Deactivate lever
    case DEACTIVATE_LEVER:
      return assign(state, {
        is_active: false,
      });

    // Activate lever base
    case ACTIVATE_LEVER_BASE:
      return assign(state, {
        has_base: true,
        cable_length: 1000,
      });

    // Deactivate lever base
    case DEACTIVATE_LEVER_BASE:
      return assign(state, {
        has_base: false,
      });

    // Activate lever hand-rest
    case ACTIVATE_LEVER_HAND_REST:
      return assign(state, {
        has_hand_rest: true,
      });

    // Deactivate lever hand-rest
    case DEACTIVATE_LEVER_HAND_REST:
      return assign(state, {
        has_hand_rest: false,
      });

    // Initialize lever reset
    case INIT_RESET_LEVER:
      return assign(state, {
        resetting: {
          isConfirmed: false,
        },
      });

    // We're resetting!
    case RESET_LEVER:
      return assign(state, {
        resetting: {
          isConfirmed: true,
        },
      });

    // Cancel the reset
    case CANCEL_RESET_LEVER:
      return assign(state, {
        resetting: {},
      });

    case ACTIVATE_HEAT:
      return {
        ...state,
        has_heat: action.activationType,
      };

    case DEACTIVATE_HEAT:
      return {
        ...state,
        has_heat: 'NONE',
      };

    case ACTIVATE_HAPTIC_FEEDBACK:
      return assign(state, {
        has_haptic_feedback: true,
      });

    case DEACTIVATE_HAPTIC_FEEDBACK:
      return assign(state, {
        has_haptic_feedback: false,
      });

    default:
      return state;
  }
}

export const initialState = {
  entities: [],
  canOptions: {},
  isFetching: false,
  isFetchingCANOptions: false,
  mirroring: {},
  error: null,
  canOptionsError: null,
  leverType: constants.L8,
};

export function levers(state = initialState, action) {
  switch (action.type) {
    case SET_LEVER_TYPE:
      console.log('=== REDUCER: SET_LEVER_TYPE ===');
      console.log('Previous state:', state);
      console.log('Action:', action);
      
      const newState = {
        ...state,
        entities: state.entities.map(lever => ({
          ...lever,
          lever_type: action.leverType
        }))
      };
      
      console.log('New state:', newState);
      return newState;

    // Tell the world we're fetching levers
    case LOAD_LEVERS:
      return assign(state, {
        isFetching: true,
      });

    // Tell the world we're fetching lever CAN options
    case LOAD_LEVER_CAN_SETTINGS:
      return assign(state, {
        isFetchingCANOptions: true,
      });

    // Add received levers to entities
    case RECEIVE_LEVERS:
      return assign({}, state, {
        entities: action.levers.map(lever => ({
          ...initialEntityState,
          ...lever,  // Keep the lever_type from the API response
        })),
        leverType: action.levers[0].lever_type || state.leverType,
        isFetching: false,
      });

    // Add received lever CAN settings
    case RECEIVE_LEVER_CAN_SETTINGS:
      return assign(state, {
        // Map to usable format here instead of on each render
        canOptions: {
          bus_termination: tuplesToOptions(action.settings.bus_termination),
          source_address: tuplesToOptions(action.settings.source_address),
        },
        isFetchingCANOptions: false,
      });

    // Add receive error and stop fetching
    case RECEIVE_LEVERS_ERROR:
      return assign(state, {
        error: action.error,
        isFetching: false,
      });

    // Add CAN settings error and stop fetching
    case RECEIVE_LEVER_CAN_SETTINGS_ERROR:
      return assign(state, {
        canOptionsError: action.error,
        isFetchingCANOptions: false,
      });

    // Initialize mirroring
    case INIT_MIRROR_LEVER:
      return assign(state, {
        mirroring: {
          id: action.id,
          isConfirmed: false,
        },
      });

    // Confirm mirroring
    case MIRROR_LEVER:
      return assign(state, {
        mirroring: {
          id: action.id,
          isConfirmed: true,
        },
      });

    // Reset mirroring state
    case CANCEL_RESET_MIRROR:
      return assign(state, {
        mirroring: {},
      });

    // Pass these actions to a single entity
    case RECEIVE_LEVER:
    case CHANGE_CABLE_OUT_LENGTH:
    case CHANGE_LEVER_CAN_SETTINGS:
    case CHANGE_LABEL:
    case CHANGE_COLOR:
    case ADD_ACCESSORY:
    case ACTIVATE_LEVER:
    case DEACTIVATE_LEVER:
    case ACTIVATE_LEVER_BASE:
    case DEACTIVATE_LEVER_BASE:
    case ACTIVATE_LEVER_HAND_REST:
    case DEACTIVATE_LEVER_HAND_REST:
    case REMOVE_ACCESSORY:
    case CLEAR_ACCESSORIES:
    case INIT_RESET_LEVER:
    case RESET_LEVER:
    case CANCEL_RESET_LEVER:
    case ACTIVATE_HEAT:
    case DEACTIVATE_HEAT:
    case ACTIVATE_HAPTIC_FEEDBACK:
    case DEACTIVATE_HAPTIC_FEEDBACK:
      return assign(state, {
        entities: state.entities.map((lever) => entity(lever, action)),
      });

    default:
      return state;
  }
}
