import React from 'react';

import { staticUrl } from '../utils';

import DocumentLink from './DocumentLink';

/**
 * Links to PDF with information about the grip and components.
 */
export default function GripInfoLinks() {
  return (
    <ul className="grip-info-links">
      <li>
        <DocumentLink
          url={staticUrl('SVAB_Grip_L8_Product_and_Technical_Information-EN_G.pdf')}
          text={global.gettext('L8 grip')}
        />
      </li>
      <li>
        <DocumentLink
          url={staticUrl('L8_eJB.pdf')}
          text={global.gettext('L8 base')}
        />
      </li>
      <li>
        <DocumentLink
          url={staticUrl('L8_hand_rest.pdf')}
          text={global.gettext('L8 hand-rest')}
        />
      </li>
    </ul>
  );
}
GripInfoLinks.displayName = 'components/GripInfoLinks';
