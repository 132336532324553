import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as constants from '../constants';
import * as SelectionHelpers from '../helpers/selection';
import * as Utils from '../utils';
import Position from '../containers/Position';
import Selection from '../containers/Selection';

// import heatIcon from '../../../../static/img/heat_symbol.png';

// import vibrationIcon from '../../../../static/img/vibration_symbol.png';

import GripAddon from './GripAddon';
import GripImage from './GripImage';
import GripLabel from './GripLabel';
import StaticImage from './StaticImage';

/**
 * Get positions per component, lever, front and direction.
 *
 * @param {Array} allPositions - All available positions.
 * @param {Object} selections - Selections for the current grip (one side of
 *    left or right).
 * @param {Object} editSelections - Store state for editSelection.
 * @return {Array}
 */
const getPositions = (allPositions, selections, editSelections) => {
  let componentId = null;
  let positions = [];

  if (editSelections.dragging.id) {
    componentId = editSelections.dragging.id;
  } else if (editSelections.moving.id) {
    componentId = Utils.getById(selections, editSelections.moving.id).component;
  }

  if (componentId !== null) {
    positions = allPositions.filter(
      (position) =>
        position.component === componentId &&
        position.lever === editSelections.leverId &&
        position.is_front === editSelections.is_front &&
        position.direction ===
          (editSelections.dragging.direction
            ? editSelections.dragging.direction
            : editSelections.moving.direction),
    )[0].entities;
  }
  return positions;
};

/**
 * One side of a lever grip.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.lever - Lever object.
 * @param {Object} props.is_front - If viewing the front of the grip.
 * @param {Object} [props.isInteractive] - If the grip should be interactive
 *   instead of just display the current state. Defaults to true.
 * @param {number} [props.highlightSelection] - ID of selection to highlight.
 * @param {Function} props.onHeatToggle - Function to toggle heat.
 * @param {Function} props.onHapticFeedbackToggle - Function to toggle haptic feedback.
 * @return {Object} React element.
 */
export default function Grip(props) {
  const {
    highlightSelection,
    is_front,
    isInteractive,
    lever,
    onBaseToggle,
    onHandRestToggle,
    showSelectionFunctions,
    onHeatToggle,
    onHapticFeedbackToggle,
  } = props;
  const storeState = global.store.getState();
  const { editSelections } = storeState;
  const selections = SelectionHelpers.getGripSelections(
    storeState.selections.entities,
    lever,
    is_front,
  );
  const side = is_front ? 'front' : 'back';
  const direction = constants.GRIP_STRING[lever.grip];
  const positions = isInteractive
    ? getPositions(storeState.positions, selections, editSelections)
    : [];
  const wrapClass = classnames(
    `grip-wrap grip-wrap-${side} grip-wrap-${direction}`,
    {
      'grip-interactive': isInteractive,
      'grip-static': !isInteractive,
    },
  );

  const shouldHaveHandRest =
    lever.lever_type === 'A9' || lever.lever_type === 'L8';
  const hasLabel = lever.lever_type === 'A9' || lever.lever_type === 'L8';

  const getHandRestName = () => {
    if (!shouldHaveHandRest) return null;
    if (lever.lever_type === 'A9') {
      return 'hand-restA9';
    }
    return 'hand-rest';
  };

  const isS2Lever = lever.lever_type === 'S2';
  const isB1Lever = lever.lever_type === 'B1';
  const isA9Lever = lever.lever_type === 'A9';
  let gridClassName = isB1Lever
    ? 'component-grid component-grid-b1'
    : `component-grid component-grid-${side} component-grid-${
        constants.GRIP_STRING[lever.grip]
      }`;
  const heatCompatible = isA9Lever;
  const hapticFeedbackCompatible = isA9Lever || isS2Lever || isB1Lever;
  const heatActive = lever.has_heat !== 'NONE';
  const hapticFeedbackActive = lever.has_haptic_feedback;

  const HEAT_ACTIVATION_OPTIONS = [
    { value: 'CAN', label: 'Activated by CAN bus' },
    { value: 'BUTTON', label: 'Activated with joystick button' },
  ];

  gridClassName += isA9Lever ? ` grid-positioning-a9-${side}-${direction}` : '';

  return (
    <div className={wrapClass}>
      <div className={`grip grip-${side} grip-${direction}`}>
        <GripImage
          is_front={is_front}
          color={lever.color}
          useJpeg={!is_front}
          leverType={lever.lever_type}
        />
        <div className={gridClassName}>
          {selections.map((selection) => (
            <Selection
              key={selection.pk}
              selection={selection}
              availablePositions={positions.length}
              grip={lever.grip}
              isInteractive={isInteractive}
              isHighlighted={selection.pk === highlightSelection}
              showFunction={showSelectionFunctions}
            />
          ))}
          {positions.map((position) => (
            <Position
              key={`${position.x}-${position.y}`}
              position={position}
              editSelections={editSelections}
            />
          ))}
        </div>
        {is_front && hasLabel && <GripLabel lever={lever} />}
      </div>
      {is_front && (
        <div
          className={`grip-addons grip-addons-${side} grip-addons-${direction}`}
        >
          {heatActive && heatCompatible && (
            <div className={`grip-addon--heat grip-heat-${direction}`}>
              <StaticImage
                name={'a9-heat-upscale.png'}
                alt="Heat symbol in grip"
              />
            </div>
          )}
          {hapticFeedbackActive && hapticFeedbackCompatible && (
            <div
              className={`grip-addon--vibration grip-vibration-${direction}`}
            >
              <StaticImage
                name={'a9-vibration-upscale.png'}
                alt="Haptic Feedback symbol in grip"
              />
            </div>
          )}

          <div className={`grip-options-${direction}`}>
            {hapticFeedbackCompatible && (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onHapticFeedbackToggle(!lever.has_haptic_feedback);
                  }
                }}
                onClick={() =>
                  onHapticFeedbackToggle(!lever.has_haptic_feedback)
                }
                className={`grip-option-${direction}`}
              >
                <StaticImage
                  name="vibration_symbol.png"
                  alt="Haptic Feedback"
                  className={`option-icon ${
                    lever.has_haptic_feedback ? 'active' : ''
                  }`}
                />
                <div style={{ cursor: 'pointer' }}>
                  <span
                    style={{ float: direction === 'right' ? 'right' : 'left' }}
                  >
                    HAPTIC
                  </span>{' '}
                  <br />
                  <span>FEEDBACK</span>
                </div>
              </div>
            )}
            {heatCompatible && (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onHeatToggle(
                      lever.has_heat === 'NONE'
                        ? HEAT_ACTIVATION_OPTIONS[0].value
                        : 'NONE',
                    );
                  }
                }}
                onClick={() =>
                  onHeatToggle(
                    lever.has_heat === 'NONE'
                      ? HEAT_ACTIVATION_OPTIONS[0].value
                      : 'NONE',
                  )
                }
                className={`grip-option-${direction}`}
              >
                <StaticImage
                  name="heat_symbol.png"
                  alt="Heat"
                  className={`option-icon ${
                    lever.has_heat !== 'NONE' ? 'active' : ''
                  }`}
                />
                <div style={{ cursor: 'pointer' }}>
                  <span
                    style={{ float: direction === 'right' ? 'right' : 'left' }}
                  >
                    GRIP
                  </span>{' '}
                  <br />
                  <span>HEATING</span>
                </div>
              </div>
            )}
          </div>
          <GripAddon
            name={getHandRestName()}
            isActive={lever.has_hand_rest}
            onToggle={onHandRestToggle}
            isInteractive={isInteractive}
          />
          <GripAddon
            name="base"
            isActive={lever.has_base}
            onToggle={onBaseToggle}
            isInteractive={isInteractive}
          />
        </div>
      )}
    </div>
  );
}
Grip.displayName = 'components/Grip';
Grip.propTypes = {
  highlightSelection: PropTypes.number,
  is_front: PropTypes.bool.isRequired,
  isInteractive: PropTypes.bool,
  lever: PropTypes.object.isRequired,
  onBaseToggle: PropTypes.func,
  onHandRestToggle: PropTypes.func,
  showSelectionFunctions: PropTypes.bool,
  onHeatToggle: PropTypes.func,
  onHapticFeedbackToggle: PropTypes.func,
};
Grip.defaultProps = {
  highlightSelection: null,
  isInteractive: true,
  onBaseToggle: null,
  onHandRestToggle: null,
  showSelectionFunctions: false,
  onHeatToggle: null,
  onHapticFeedbackToggle: null,
};
