// Grip
export const LEFT = 'LT';
export const RIGHT = 'RT';
export const GRIP_STRING = {
  LT: 'left',
  RT: 'right',
};
export const GRIP_NAME = {
  LT: global.gettext('Left'),
  RT: global.gettext('Right'),
};

// Direction
export const NOT_APPLICABLE = 'NA';
export const HORIZONTAL = 'HL';
export const VERTICAL = 'VL';
export const DIRECTION_STRING = {
  NA: 'n/a',
  HL: 'horizontal',
  VL: 'vertical',
};
export const DIRECTION_IMAGE = {
  NA: 'default',
  HL: 'horizontal',
  VL: 'vertical',
};

// Color
export const GRAY = 'GR';
export const BLACK = 'BL';
export const COLOR_STRING = {
  GR: 'gray',
  BL: 'black',
  BK: 'black',
};
export const COLOR_NAME = {
  BK: global.gettext('Blank'),
  GR: global.gettext('Gray'),
  BL: global.gettext('Black'),
};

// Label
export const BLANK = 'BK';
export const SVAB = 'SB';
export const CUSTOM = 'CM';
export const LABEL_STRING = {
  BK: 'blank',
  SB: 'svab',
  CM: 'custom',
};
export const LABEL_NAME = {
  BK: global.gettext('No label'),
  SB: 'SVAB',
  CM: global.gettext('Custom'),
};

// Settings
export const TEXT = 'TX';
export const RADIO = 'RD';
export const CHECKBOX = 'CB';
export const SETTINGS_STRING = {
  TX: 'text',
  RD: 'radio',
  CB: 'checkbox',
};

// Lever types
export const L8 = 'L8';
export const A9 = 'A9';
export const B1 = 'B1';
export const S2 = 'S2';

// Some bus termination values are only available if a base is selected
export const BUS_TERMINATION_VALUES_REQUIRE_BASE = ['120'];

// Make sure L8 is the default
export const DEFAULT_LEVER_TYPE = L8;
