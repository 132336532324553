import React from 'react';
import PropTypes from 'prop-types';

import { staticUrl } from '../utils';

/**
 * A static image.
 *
 * @param {Object} props - Which image size to use.
 * @param {string} props.name - Image name/path inside the static/img folder.
 */
export default function StaticImage(props) {
  const { alt, name, ...passProps } = props;

  return <img {...passProps} src={staticUrl(`img/${name}`)} alt={alt} data-original={`img/${name}`} />;
}
StaticImage.displayName = 'components/StaticImage';
StaticImage.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string,
};
StaticImage.defaultProps = {
  alt: '',
};
