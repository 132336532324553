import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isProjectLocked, staticUrl } from '../utils';
import * as constants from '../constants';
import * as SelectionHelpers from '../helpers/selection';
import Accessories from '../containers/Accessories';
import LeverTools from '../containers/LeverTools';

import { Panel, Group, Title } from './PagePanel';
import Button from './forms/Button';
import Cables from './Cables';
import DocumentLink from './DocumentLink';
import Grip from './Grip';
import Icon from './Icon';
import LabelSelect from './LabelSelect';
import LeverCANSettings from './LeverCANSettings';
import MessageBox from './MessageBox';

/**
 * Lever panel with both sides of one grip.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.canMirror - If mirroring is possible.
 * @param {boolean} props.isInactive - If the panel is inactive.
 * @param {Object} props.lever - Lever state object.
 * @param {Function} props.onBaseSourceAddressChange - Base CAN source address
 *   setting change handler.
 * @param {Function} props.onBaseToggle - Base active/inactive toggle.
 * @param {Function} props.onBusTerminationChange - Lever CAN bus termination
 *   setting change handler.
 * @param {Function} props.onCableChange - Cable length change event handler.
 * @param {Function} props.onHandRestToggle - Hand-rest active/inactive toggle.
 * @param {Function} props.onLabelChange - Label change event handler.
 * @param {Function} props.onLeverEdit - Place components button click handler.
 *    Is passed `is_front` as true or false.
 * @param {Function} props.onSourceAddressChange - Lever CAN source address
 *   setting change handler.
 * @param {boolean} props.hasLabel - If the lever has a label.
 * @param {boolean} props.hasHandRest - If the lever has a hand-rest.
 * @return {Object} React element.
 */
export default function LeverPanel(props) {
  const {
    canMirror,
    children,
    isInactive,
    lever,
    onBaseSourceAddressChange,
    onBaseToggle,
    onBusTerminationChange,
    onCableChange,
    onHandRestToggle,
    onLabelChange,
    onLeverEdit,
    onSourceAddressChange,
    hasLabel,
    hasHandRest,
    onHeatToggle,
    onHapticFeedbackToggle,
  } = props;
  const leverId = lever.pk;
  const leverName = constants.GRIP_NAME[lever.grip];
  const className = classnames('lever-panel', {
    'lever-panel--inactive': isInactive,
  });
  const isLocked = isProjectLocked();
  const storeState = global.store.getState();
  const backSelections = SelectionHelpers.getGripSelections(
    storeState.selections.entities,
    lever,
    false,
  );
  const hasTopBackPosition = SelectionHelpers.hasTopBackPosition(
    backSelections,
    lever,
  );

  lever.lever_type = storeState.levers.leverType;


  return (
    <Panel className={className} isHalf>
      <Group>
        <Title>{leverName}</Title>
        <LeverTools lever={lever} canMirror={canMirror} />
      </Group>

      {children}

      <Group className="lever-front">
        <Title level={3}>{global.gettext('Front')}</Title>

        <div className="lever-controls">
          <div>
            <Button onClick={onLeverEdit.bind(null, true)} disabled={isLocked}>
              <Icon name="grid" />
              <span className="text">{global.gettext('Place components')}</span>
            </Button>
            {hasLabel && <LabelSelect lever={lever} onChange={onLabelChange} />}
          </div>
          <div className="lever-controls-extras">
            <label>
              <input
                type="checkbox"
                onChange={onBaseToggle}
                checked={lever.has_base}
              />
              <span>Include base</span>
            </label>
            <DocumentLink
              url={staticUrl('L8_eJB.pdf')}
              text={global.gettext('Base data sheet')}
              hasHiddenText
            />
            {hasHandRest && (
              <>
                <label>
                  <input
                    type="checkbox"
                    onChange={onHandRestToggle}
                    checked={lever.has_hand_rest}
                  />
                  <span>Include hand-rest</span>
                </label>
                <DocumentLink
                  url={staticUrl('L8_hand_rest.pdf')}
                  text={global.gettext('Hand-rest data sheet')}
                  hasHiddenText
                />
              </>
            )}
          </div>
        </div>

        <Grip
          lever={lever}
          is_front
          isInteractive={!isLocked}
          onBaseToggle={onBaseToggle}
          onHandRestToggle={onHandRestToggle}
          onHeatToggle={(state) => onHeatToggle(state)}
          onHapticFeedbackToggle={(state) => onHapticFeedbackToggle(state)}
        />
      </Group>

      <Group className="lever-back">
        <Title level={3}>{global.gettext('Back')}</Title>

        <div className="lever-messages">
          {!hasTopBackPosition && (
            <MessageBox type="info">
              <p>
                <strong>{`${global.gettext('Important!')} `}</strong>
                {global.gettext(
                  'There must be a component placed in the top position',
                )}
              </p>
            </MessageBox>
          )}
        </div>

        <div className="lever-controls">
          <Button onClick={onLeverEdit.bind(null, false)} disabled={isLocked}>
            <Icon name="grid" />
            <span className="text">{global.gettext('Place components')}</span>
          </Button>
        </div>

        <Grip lever={lever} is_front={false} isInteractive={!isLocked} />
      </Group>

      <Group className="lever-accessories">
        <Title level={3}>{global.gettext('Accessories')}</Title>
        {lever.has_base && (
          <p>
            {global.gettext('Accessories are only relevant without a base')}
          </p>
        )}
        {!lever.has_base && (
          <React.Fragment>
            <p>{global.gettext('You can add one of each accessory')}</p>
            <Accessories lever={lever} />
          </React.Fragment>
        )}
      </Group>

      <Group>
        <Title level={3}>{global.gettext('Cables')}</Title>
        <Cables
          leverId={leverId}
          cableLength={lever.cable_length}
          cableLengthError={lever.cable_length_error}
          cables={lever.cables}
          hasBase={lever.has_base}
          onChange={onCableChange}
          disabled={lever.has_base || lever.has_can || isLocked}
        />
      </Group>

      {Boolean(lever.has_can || lever.has_base) && (
        <Group>
          <Title level={3}>{global.gettext('CAN settings')}</Title>
          <LeverCANSettings
            lever={lever}
            onBaseSourceAddressChange={onBaseSourceAddressChange}
            onBusTerminationChange={onBusTerminationChange}
            onSourceAddressChange={onSourceAddressChange}
          />
        </Group>
      )}
    </Panel>
  );
}
LeverPanel.displayName = 'components/LeverPanel';
LeverPanel.propTypes = {
  canMirror: PropTypes.bool,
  children: PropTypes.node,
  isInactive: PropTypes.bool,
  lever: PropTypes.object.isRequired,
  onBaseSourceAddressChange: PropTypes.func.isRequired,
  onBaseToggle: PropTypes.func.isRequired,
  onBusTerminationChange: PropTypes.func.isRequired,
  onCableChange: PropTypes.func.isRequired,
  onHandRestToggle: PropTypes.func.isRequired,
  onLabelChange: PropTypes.func.isRequired,
  onLeverEdit: PropTypes.func.isRequired,
  onSourceAddressChange: PropTypes.func.isRequired,
  hasLabel: PropTypes.bool,
  hasHandRest: PropTypes.bool,
  onHeatToggle: PropTypes.func,
  onHapticFeedbackToggle: PropTypes.func,
};
LeverPanel.defaultProps = {
  children: null,
  canMirror: false,
  isInactive: false,
  hasLabel: false,
  hasHandRest: true,
  onHeatToggle: () => {},
  onHapticFeedbackToggle: () => {},
};
