import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BUS_TERMINATION_VALUES_REQUIRE_BASE } from '../constants';
import { format } from '../helpers/string';
import { assign, getCANSettingLabel, isProjectLocked } from '../utils';

import Label from './forms/Label';
import MessageBox from './MessageBox';
import RadioGroup from './forms/RadioGroup';
import Select from './forms/Select';

/**
 * CAN settings form for a lever.
 *
 * @param {Object} props - Component props.
 */
export default function LeverCANSettings(props) {
  const {
    lever,
    onBaseSourceAddressChange,
    onBusTerminationChange,
    onSourceAddressChange,
  } = props;
  const { canSettings, levers } = global.store.getState();
  const { canOptions, isFetching, isFetchingCANOptions } = levers;
  const { has_base, can_settings: values, grip } = lever;
  const otherLever = levers.entities.find((l) => l.pk !== lever.pk);
  const selectedCANProtocolName = getCANSettingLabel(
    canSettings.options.protocol,
    canSettings.values.protocol,
  );
  const isLocked = isProjectLocked();

  const busTerminationName = `can-settings-bus-termination-${grip}`;
  const sourceAddressName = `can-settings-source-address-${grip}`;
  const baseSourceAddressName = `can-settings-base-source-address-${grip}`;
  const sectionClassName = 'can-settings-section';

  // Some bus termination values are only available if a base is selected
  const hasInvalidBusTermination =
    !has_base &&
    BUS_TERMINATION_VALUES_REQUIRE_BASE.includes(values.bus_termination);

  // If the current source address value doesn't exist in the available options,
  // it was probably selected for a different CAN protocol
  const otherLeverSourceAddresses = [
    otherLever ? otherLever.can_settings.source_address : '',
    otherLever && otherLever.has_base
      ? otherLever.can_settings.source_address_base
      : '',
  ].filter((val) => Boolean(val));
  const hasSourceAddressOutOfRange =
    canOptions && canOptions.source_address
      ? !canOptions.source_address.some(
        (opt) => opt.value === values.source_address,
      )
      : false;
  const hasBaseSourceAddressOutOfRange =
    canOptions && canOptions.source_address
      ? !canOptions.source_address.some(
        (opt) => opt.value === values.source_address_base,
      )
      : false;

  const hasDuplicateSourceAddress =
    (has_base &&
      values.source_address &&
      values.source_address === values.source_address_base) ||
    otherLeverSourceAddresses.includes(values.source_address);
  const hasDuplicateBaseSourceAddress =
    (values.source_address_base &&
      values.source_address_base === values.source_address) ||
    otherLeverSourceAddresses.includes(values.source_address_base);

  const sourceAddressRangeWarning = (
    <MessageBox type="warning">
      <p>
        {format(
          global.gettext(
            'The selected source address is out of range for %1, please choose a different one',
          ),
          selectedCANProtocolName
            ? format(global.gettext('protocol %1'), selectedCANProtocolName)
            : global.gettext('the selected protocol'),
        )}
      </p>
    </MessageBox>
  );

  const SOURCE_ADDRESS_DUPLICATE_WARNING = (
    <MessageBox type="warning">
      <p>
        {global.gettext(
          "The same source address can't be used for multiple nodes",
        )}
      </p>
    </MessageBox>
  );

  return (
    <div className="can-settings">
      {hasInvalidBusTermination && (
        <MessageBox type="warning">
          <p>
            {global.gettext(
              'The selected CAN bus termination is only available if a base is included, please choose a different one',
            )}
          </p>
        </MessageBox>
      )}
      <RadioGroup
        className={classnames(
          sectionClassName,
          `${sectionClassName}--bus-termination`,
        )}
        groupLabel={`${global.gettext('CAN bus termination')}:`}
        onChange={onBusTerminationChange}
        name={busTerminationName}
        options={
          canOptions && canOptions.bus_termination
            ? canOptions.bus_termination.map((opt) => {
              if (
                !has_base &&
                  BUS_TERMINATION_VALUES_REQUIRE_BASE.includes(
                    String(opt.value),
                  )
              ) {
                return assign(opt, { disabled: true });
              }
              return opt;
            })
            : []
        }
        selected={values.bus_termination}
        disabled={isLocked || isFetching}
      />

      {hasSourceAddressOutOfRange && sourceAddressRangeWarning}
      {hasDuplicateSourceAddress && SOURCE_ADDRESS_DUPLICATE_WARNING}
      {lever.has_can && (
        <div
          className={classnames(
            sectionClassName,
            `${sectionClassName}--source-address`,
          )}
        >
          <Label
            text={global.gettext('Grip source address')}
            htmlFor={sourceAddressName}
          />
          <Select
            id={sourceAddressName}
            onChange={onSourceAddressChange}
            options={canOptions && canOptions.source_address ? canOptions.source_address.map((opt) =>
              // Don't allow selecting duplicate values
              (otherLeverSourceAddresses.includes(opt.value) ||
              (has_base &&
                opt.value &&
                opt.value === values.source_address_base)
                ? assign(opt, { disabled: true })
                : opt),
            ) : []}
            value={values.source_address}
            disabled={isLocked || isFetching || isFetchingCANOptions}
          />
        </div>
      )}

      {has_base && (
        <React.Fragment>
          {hasBaseSourceAddressOutOfRange && sourceAddressRangeWarning}
          {hasDuplicateBaseSourceAddress && SOURCE_ADDRESS_DUPLICATE_WARNING}
          <div
            className={classnames(
              sectionClassName,
              `${sectionClassName}--source-address`,
            )}
          >
            <Label
              text={global.gettext('Base source address')}
              htmlFor={baseSourceAddressName}
            />
            <Select
              id={baseSourceAddressName}
              onChange={onBaseSourceAddressChange}
              options={canOptions && canOptions.source_address ? canOptions.source_address.map((opt) =>
                // Don't allow selecting duplicate values
                (otherLeverSourceAddresses.includes(opt.value) ||
                (opt.value && opt.value === values.source_address)
                  ? assign(opt, { disabled: true })
                  : opt),
              ) : []}
              value={values.source_address_base}
              disabled={isLocked || isFetching || isFetchingCANOptions}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
LeverCANSettings.displayName = 'components/LeverCANSettings';
LeverCANSettings.propTypes = {
  lever: PropTypes.object.isRequired,
  onBaseSourceAddressChange: PropTypes.func.isRequired,
  onBusTerminationChange: PropTypes.func.isRequired,
  onSourceAddressChange: PropTypes.func.isRequired,
};
