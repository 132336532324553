import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as constants from '../constants';
import * as Utils from '../utils';

import Button from './forms/Button';
import GripImage from './GripImage';

/**
 * A single grip switch button
 *
 * @param {Object} props - Component props.
 * @param {Array} props.lever - Lever object.
 * @param {boolean} props.is_front - If showing the front side.
 */
export function Control(props) {
  const { lever, is_front, isSelected, onControlClick } = props;
  const className = classnames('grip-switch-control', {
    'grip-switch-control-selected': isSelected,
    'grip-switch-control-active': lever.is_active,
    'grip-switch-control-inactive': !lever.is_active,
  });
  const imgClassName = lever.grip === constants.RIGHT ? 'flip' : undefined;
  const contents = [
    <GripImage
      key={1}
      variant="thumbnail"
      is_front={is_front}
      color={lever.color}
      className={imgClassName}
      leverType={lever.lever_type}
    />,
    <span key={2}>
      {is_front ? global.gettext('Front') : global.gettext('Back')}
    </span>,
  ];

  if (lever.is_active && !isSelected) {
    return (
      <Button
        onClick={onControlClick.bind(null, lever, is_front)}
        className={className}
        isSecondary
      >
        {contents}
      </Button>
    );
  }
  return <span className={className}>{contents}</span>;
}
Control.displayName = 'components/GripSwitcher/Control';
Control.propTypes = {
  lever: PropTypes.object.isRequired,
  is_front: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  onControlClick: PropTypes.func.isRequired,
};
Control.defaultProps = {
  isSelected: false,
};

/**
 * Grip switch buttons.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.levers - All lever entities.
 * @return {Object} React element.
 */
export default function GripSwitcher(props) {
  const { levers, editSelections, onControlClick } = props;

  return (
    <div className="grip-switcher">
      {Utils.getOrderedLevers(levers).map((lever) => {
        const className = classnames(
          `grip-switch-${constants.GRIP_STRING[lever.grip]}`,
          {
            'grip-switch-inactive': !lever.is_active,
          },
        );
        const isSelected = (is_front) =>
          editSelections.leverId === lever.pk &&
          editSelections.is_front === is_front;

        return (
          <div key={lever.pk} className={className}>
            <h4>{constants.GRIP_NAME[lever.grip]}</h4>
            <Control
              lever={lever}
              is_front
              onControlClick={onControlClick}
              isSelected={isSelected(true)}
            />
            <Control
              lever={lever}
              is_front={false}
              onControlClick={onControlClick}
              isSelected={isSelected(false)}
            />
          </div>
        );
      })}
    </div>
  );
}
GripSwitcher.displayName = 'components/GripSwitcher';
GripSwitcher.propTypes = {
  levers: PropTypes.array.isRequired,
  editSelections: PropTypes.object.isRequired,
  onControlClick: PropTypes.func.isRequired,
};
