import React from 'react';
import PropTypes from 'prop-types';

import * as ProjectActions from '../actions/project';
import * as LeverActions from '../actions/levers';

import * as utils from '../utils';

import Header from './Header';

const ChooseJoystickType = () => {
  const joystickTypesSmall = [
    {
      type: 'L8',
      img: 'grip-front-gray',
      info: [
        '<p>The market leading L8 joystick grip ergonomically allows you to control two roller functions simultaneously using your thumb and index finger.</p>',
        '<span>- Up to 3 rollers per grip</span> <br/>',
      ],
      link: 'https://svab.se/en/modal/l8/',
    },
    {
      type: 'S2',
      img: 'S2-grip-front-right',
      info: [
        '<p>The S2 joystick grip is a slim, ergonomically designed grip.</p>',
        '<span>- Up to 1 roller per grip</span> <br/>',
        '<span>- Well suited for smaller hands</span>',
      ],
      link: 'https://svab.se/en/modal/s2/',
    },
    {
      type: 'B1',
      img: 'B1-grip-front',
      info: [
        '<p>The B1 is designed for operators who require only the most essential functions to succeed in their tasks.</p>',
        '<span>- Well suited for smaller hands</span>',
      ],
      link: 'https://svab.se/en/modal/b1/',
    },
  ];

  const joystickTypesLarge = [
    {
      type: 'A9',
      img: 'A9-grip-front-right',
      info: [
        "<p>The new A9 grip is SVAB's most advanced grip and is specialy developed to meet a growing need for ergonomics and the ability to control more functions.</p>",
        '<span>- Up to 5 rollers per grip <br /> - Heating (option) <br/> - Haptic Feedback (option)</span>',
      ],
      link: 'https://svab.se/en/modal/a9/',
    },
  ];

  const handleJoystickSelection = (joystick) => {
    console.log('=== JOYSTICK SELECTION START ===');
    console.log(`Selected joystick type: ${joystick.type}!!`);

    // First ensure levers are loaded
    global.store
      .dispatch(LeverActions.requestLevers())
      .then(() => {
        const levers = global.store.getState().levers.entities;
        console.log('Loaded levers:', levers);

        // Update store state first
        global.store.dispatch(ProjectActions.setLeverType(joystick.type));
        global.store.dispatch(LeverActions.setLeverType(joystick.type));

        // Then update backend
        const updatePromises = levers.map((lever) =>
          global.store.dispatch(
            LeverActions.putLeverType(lever.pk, joystick.type),
          ),
        );

        // Wait for all updates to complete, then load CAN settings once
        return Promise.allSettled(updatePromises)
          .then(() => {
            return global.store.dispatch(
              LeverActions.requestLeverCANSettings(),
            );
          })
          .then(() => {
            global.store.dispatch(ProjectActions.viewProject());
          });
      })
      .catch((error) => {
        console.error('Error in handleJoystickSelection:', error);
        global.store.dispatch(ProjectActions.viewProject());
      });
  };
  const toggleInfo = (e) => {
    e.stopPropagation();
    const infoContent = e.currentTarget.nextElementSibling;
    if (
      infoContent.style.display === 'none' ||
      infoContent.style.display === ''
    ) {
      infoContent.style.display = 'block';
    } else {
      infoContent.style.display = 'none';
    }
  };

  const closeInfo = (e, id) => {
    e.stopPropagation();
    const infoContent = document.getElementById(id);
    infoContent.style.display = 'none';
  };

  const JoystickSelectContainer = ({ type, onSelect }) => {
    JoystickSelectContainer.propTypes = {
      type: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired,
    };

    return (
      <div className="joystick-select-container-small">
        <span>{type} Joystick Grip</span>
        <button
          className="joystick-select-button"
          type="button"
          onClick={onSelect}
        >
          Select
        </button>
      </div>
    );
  };

  React.useEffect(() => {
    global.store.dispatch(ProjectActions.viewProject());
  }, []);


  const renderJoystick = (joystick, className) => (
    <div
      className={className}
      style={{
        backgroundImage: `url(${utils.staticUrl(`img/${joystick.type}.png`)})`,
      }}
      key={joystick.type}
      type="button"
      onClick={() => handleJoystickSelection(joystick)}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={toggleInfo}
        className="joystick-info-button"
        onClick={toggleInfo}
      >
        <img
          src={utils.staticUrl('img/icons/info.png')}
          alt="info"
          className="joystick-info-icon"
        />
      </div>
      <div
        role="button"
        className="joystick-info-content"
        id={joystick.type}
        style={{ display: 'none' }}
        onClick={(e) => closeInfo(e, joystick.type)}
        tabIndex={0}
        onKeyDown={(e) => closeInfo(e, joystick.type)}
      >
        {joystick.info.map((info) => (
          <div key={info} dangerouslySetInnerHTML={{ __html: info }} />
        ))}
        <a
          href={joystick.link}
          target="_blank"
          rel="noopener noreferrer"
          className="joystick-info-link"
        >
          Read more
        </a>
      </div>
      <JoystickSelectContainer
        type={joystick.type}
        onSelect={() => handleJoystickSelection(joystick)}
      />
    </div>
  );

  return (
    <div className="choose-joystick-wrapper">
      <Header />
      <fieldset className="">
        <legend className="product-type-wrapper">
          JOYSTICK GRIP AND OPTIONAL JOYSTICK BASE
        </legend>
        <div className="choose-joystick-container">
          {joystickTypesLarge.map((joystick) =>
            renderJoystick(joystick, 'choose-joystick-large-select'),
          )}
        </div>

        <div className="choose-joystick-container">
          {joystickTypesSmall.map((joystick) =>
            renderJoystick(joystick, 'choose-joystick-small-select'),
          )}
        </div>
      </fieldset>
    </div>
  );
};

export default ChooseJoystickType;
