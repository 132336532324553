import React from 'react';

import { debug } from '../helpers/debug';
import ComponentList from '../components/ComponentList';
import * as EditSelectionsActions from '../actions/editSelections';
import GripSwitcher from '../components/GripSwitcher';
import * as ProjectActions from '../actions/project';
import Button from '../components/forms/Button';
import * as constants from '../constants';
import Grip from '../components/Grip';
import Icon from '../components/Icon';
import * as Utils from '../utils';

/**
 * Main edit view component.
 */
export default class Edit extends React.Component {
  /**
   * Load edit data when mounting.
   */
  componentDidMount = () => {
    const { editSelections } = global.store.getState();
    global.store.dispatch(
      EditSelectionsActions.editLever(
        editSelections.leverId,
        editSelections.is_front,
      ),
    );
  };

  /**
   * Reset when closing.
   */
  componentWillUnmount = () => {
    global.store.dispatch(EditSelectionsActions.resetEditSelections());
  };

  /**
   * Check if a component has any available positions.
   *
   * @param {Object} componentEntity - Component data for the current edit view.
   * @param {Array} positions - All available positions.
   * @param {Object} lever - Lever object.
   * @param {boolean} is_front - If on the front of the lever.
   * @return {boolean}
   */
  componentHasPositions = (componentEntity, positions, lever, is_front) => {
    const filteredPos = positions.filter(
      (position) =>
        position.component === componentEntity.component &&
        position.lever === lever.pk &&
        position.is_front === is_front &&
        position.direction === componentEntity.direction,
    );

    return filteredPos.length !== 0 && filteredPos[0].entities.length !== 0;
  };

  /**
   * Go back to the project view when closing.
   */
  handleClose = () => {
    global.store.dispatch(ProjectActions.viewProject());
  };

  /**
   * Set which lever and side to edit.
   *
   * @param {Object} lever - Lever object.
   * @param {boolean} is_front - If on the front of the lever.
   */
  handleControlClick = (lever, is_front) => {
    global.store.dispatch(EditSelectionsActions.editLever(lever.pk, is_front));
  };

  /**
   * Set the currently dragged component on drag start.
   *
   * @param {Object} componentEntity - Component data for the current edit view.
   * @param {Object} componentData - 'Core' component data.
   * @param {boolean} isDraggable - If the component actually is draggable.
   * @param {Object} e - HTML dragstart event.
   */
  handleDragStart = (componentEntity, componentData, isDraggable, e) => {
    if (!isDraggable) {
      e.preventDefault();
      return false;
    }

    // Firefox requires something. IE doesn't handle MIME-types
    // (e.g. text/plain).
    e.dataTransfer.setData('text', componentEntity.component.toString());

    // IE throws if trying to set effectAllowed
    try {
      e.dataTransfer.effectAllowed = 'copy';
    } catch (err) {
      debug(err);
    }

    // Set the 'ghost image' when dragging.
    // If using an image element or object, the browser will just use the image
    // data and ignore any other DOM properties like class names and styles.
    if (typeof e.dataTransfer.setDragImage === 'function') {
      const dragGhost = document.createElement('div');
      const dragGhostImage = document.createElement('img');
      dragGhost.className = `component-item-image drag-image drag-image-x-${
        componentData.x_size
      } drag-image-y-${componentData.y_size}`;
      dragGhostImage.src =
        componentData.image[
          constants.DIRECTION_IMAGE[componentEntity.direction]
        ];
      dragGhost.appendChild(dragGhostImage);

      let offset = 15 * Math.max(componentData.x_size, componentData.y_size);
      // Aweful but necessary, Safari has weird offset placement and clipping
      // issues using transform on the element.
      if (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
      ) {
        offset = 20;
        dragGhost.className += ' drag-image-centered';
      }

      const domDump = document.getElementById('drag-image-container');
      if (domDump.firstChild) {
        domDump.removeChild(domDump.firstChild);
      }
      domDump.appendChild(dragGhost);

      e.dataTransfer.setDragImage(dragGhost, offset, offset);
    }

    // Try to flush any possibly queued drag image tasks, to hopefully safeguard
    // against potential issues that may occur if it's half done. See:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=413795
    setTimeout(
      () =>
        global.store.dispatch(
          EditSelectionsActions.registerDragStart(
            componentEntity.component,
            componentEntity.direction,
          ),
        ),
      0,
    );
  };

  /**
   * Reset state on dragend.
   */
  handleDragEnd = () => {
    setTimeout(
      () => global.store.dispatch(EditSelectionsActions.registerDragEnd()),
      10,
    );
  };

  /**
   * Get the lever image based on the lever type and side.
   * @param {Object} lever - The lever object.
   * @param {boolean} is_front - If the lever is on the front side.
   * @returns {string} The URL of the lever image.
   */

  render() {
    const storeState = global.store.getState();
    const { editSelections } = storeState;
    const { positions } = storeState;
    const { components } = storeState;
    const levers = storeState.levers.entities;
    const isAddingSelection = storeState.selections.isAdding;
    const lever = Utils.getById(levers, editSelections.leverId);
    const baseClass = 'edit-selections';

    console.log('EditSelections render - lever:', lever);
    console.log('EditSelections render - lever type:', lever.lever_type);

    if (!lever) {
      console.error('No lever found with ID:', editSelections.leverId);
      return null;
    }

    const isB1Lever = lever.lever_type === 'B1';

    return (
      <div className={baseClass}>
        <h1 className={`${baseClass}-title`}>
          {global.gettext('Place components')}
        </h1>

        <Button onClick={this.handleClose} className={`${baseClass}-close`}>
          <Icon name="cross" />
          <span className="text">{global.gettext('Close')}</span>
        </Button>

        <GripSwitcher
          levers={levers}
          editSelections={editSelections}
          onControlClick={this.handleControlClick}
        />

        <div className={`${baseClass}-list`}>
          {components.isFetching && (
            <p>{global.gettext('Loading components')}</p>
          )}

          <ComponentList
            lever={lever}
            editSelections={editSelections}
            components={components}
            positions={positions}
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}
            isDraggable={!isAddingSelection}
          />
        </div>

        <div className={`${baseClass}-grip`}>
          {isB1Lever ? (
            <div className="component-grid-b1">
              <Grip lever={lever} is_front={editSelections.is_front} />
            </div>
          ) : (
            <Grip lever={lever} is_front={editSelections.is_front} />
          )}
        </div>
      </div>
    );
  }
}
