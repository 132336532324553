import React from 'react';

import CommInfo from '../components/CommInfo';

import EditSelections from './EditSelections';
import Project from './Project';
import ChooseJoystickType from './ChooseJoystickType';

/**
 * Main app component.
 *
 * @return {Object} React element.
 */
export default function App() {
  let MainComponent;
  const { view } = global.store.getState().project;

  switch (view) {
    case 'new-project':
      MainComponent = ChooseJoystickType;
      break;

    case 'project':
      MainComponent = Project;
      break;

    case 'edit':
      MainComponent = EditSelections;
      break;

    default:
    // No default
  }

  return (
    <div className={`app-view-${view}`}>
      <CommInfo />
      <MainComponent />
    </div>
  );
}
App.dispayName = 'containers/App';
