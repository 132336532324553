/**
 * Component helpers.
 *
 * @module helpers/component
 */

import * as Utils from '../utils';

import * as GridHelpers from './grid';

/**
 * Detect if two positions collide by checking rectangle overlap.
 *
 * @param {GridPosition} rect1 - Position object.
 * @param {GridPosition} rect2 - Position object.
 * @return {boolean}
 */
function isPositionColliding(rect1, rect2) {
  return (
    rect1.x < rect2.x + rect2.x_size &&
    rect1.x + rect1.x_size > rect2.x &&
    rect1.y < rect2.y + rect2.y_size &&
    rect1.y_size + rect1.y > rect2.y
  );
}

/**
 * Get a component's blacklisted coordinates.
 *
 * Combines any blacklisted coordinates on the component with hardcoded ones.
 *
 * @param {Object} component - Component object.
 * @param {string} grip - Lever grip, left or right as constant or string
 * @param {boolean} is_front - True for front, false for back.
 * @param {boolean} is_a9 - If the lever is an A9 lever.
 * @return {Array} List of blacklisted coordinates.
 */
export function getComponentBlacklist(component, grip, is_front, is_a9) {
  let blacklist = [];

  if (component.blacklist) {
    blacklist = is_front
      ? component.blacklist.coordinates.front
      : component.blacklist.coordinates.back;

    // Mirror when right
    if (Utils.isRightGrip(grip)) {
      blacklist = GridHelpers.getMirroredPositions(blacklist, is_front);
    }
  }

  // Merge component blacklist with hardcoded back blacklist
  if (!is_front) {
    blacklist = GridHelpers.getBackBlacklist(grip, blacklist, is_a9);
  }

  return blacklist;
}

/**
 * Calculate possible component positions.
 *
 * Loops through x and y in grid and tries to place component. Component can be
 * hindered by a selection or blacklist entry.
 *
 * @param {number} x_size - Components x size.
 * @param {number} y_size - Components y size.
 * @param {Array} blacklist - Positions blacklist.
 * @param {Array} components - All components.
 * @param {Array} selections - List of selections.
 * @param {boolean} is_front - Use front grid or not.
 * @param {Object} lever - Lever object.
 * @return {Array} List of possible positions.
 */
export function getComponentPositions(
  x_size,
  y_size,
  blacklist,
  components,
  selections,
  is_front,
  lever,
) {
  // Maybe refactor this function some time
  /* eslint-disable no-restricted-syntax, no-labels, no-continue */
  const resolution = GridHelpers.GRID_RESOLUTION;
  const positions = [];
  let id = 0;
  let grid;
  if (is_front) {
    grid = GridHelpers.GRID_FRONT;
  } else {
    grid =
      lever.lever_type === 'A9'
        ? GridHelpers.GRID_BACK_A9
        : GridHelpers.GRID_BACK;
  }

  // Define special areas for A9 back grid
  // const a9SpecialAreas = [
  //   { x: 0, y: 2, width: 4, height: 2 }, // First 4x2 area
  //   { x: 0, y: 4, width: 4, height: 2 }, // Second 4x2 area
  // ];

  // const isInSpecialArea = (position) => {
  //   return a9SpecialAreas.some(
  //     (area) =>
  //       position.x < area.x + area.width &&
  //       position.x + position.x_size > area.x &&
  //       position.y < area.y + area.height &&
  //       position.y + position.y_size > area.y,
  //   );
  // };

  // Check if a special area is already occupied
  const isSpecialAreaOccupied = (position) => {
    if (!isInSpecialArea(position)) return false;
  };

  // Loop X
  for (let x = 0; x <= grid.x; x += resolution) {
    if (x + x_size > grid.x) {
      // Out of grid
      continue;
    }

    // Loop Y
    yLoop: for (let y = 0; y <= grid.y; y += resolution) {
      if (y + y_size > grid.y) {
        // Out of grid
        continue;
      }

      const position = { id, x, y, x_size, y_size };

      if (
        blacklist.length &&
        GridHelpers.isPositionInList(position, blacklist)
      ) {
        continue;
      }

      // For A9 back grid, check special areas
      // if (leverType === 'A9' && !is_front) {
      //   if (isInSpecialArea(position) && isSpecialAreaOccupied(position)) {
      //     continue;
      //   }
      // }

      // Check for collisions against registered selections for this lever
      // and grip
      for (const selection of selections) {
        const selectionComponent = Utils.getById(
          components,
          selection.component,
        );
        const selectionSize = GridHelpers.getXYSizeByDirection(
          selectionComponent.x_size,
          selectionComponent.y_size,
          selection.direction,
        );
        const regComponent = {
          x: selection.x,
          y: selection.y,
          x_size: selectionSize.x_size,
          y_size: selectionSize.y_size,
        };

        if (isPositionColliding(position, regComponent)) {
          continue yLoop;
        }
      }

      id += 1;
      positions.push(position);
    }
  }

  return positions;
}

/**
 * Check if a component entity from editSelection has any available positions.
 *
 * @param {Object} componentEntity - Component data from editSelection.
 * @param {Array} positions - All available positions.
 * @param {Object} lever - Lever object.
 * @param {boolean} is_front - If on the front of the lever.
 * @return {boolean}
 */
export function componentHasPositions(
  componentEntity,
  positions,
  lever,
  is_front,
) {
   // Get the full component data from the store
  const componentData = global.store.getState().components.entities.find(
    (c) => c.pk === componentEntity.component,
  );
  console.log('componentData', componentData);
  // If the component is not allowed on the backside, return false
  if (!is_front && !componentData.allow_backside) {
    return false;
  }
  const filteredPos = positions.filter(
    (position) =>
      position.component === componentEntity.component &&
      position.lever === lever.pk &&
      position.is_front === is_front &&
      position.direction === componentEntity.direction,
  );

  return filteredPos.length !== 0 && filteredPos[0].entities.length !== 0;
}
