import debounce from 'lodash/debounce';

import {
  LOAD_LEVERS,
  LOAD_LEVER_CAN_SETTINGS,
  CHANGE_CABLE_OUT_LENGTH,
  CHANGE_LEVER_CAN_SETTINGS,
  RECEIVE_LEVERS,
  RECEIVE_LEVER,
  RECEIVE_LEVER_CAN_SETTINGS,
  CHANGE_LABEL,
  ADD_ACCESSORY,
  REMOVE_ACCESSORY,
  CLEAR_ACCESSORIES,
  RECEIVE_LEVERS_ERROR,
  RECEIVE_LEVER_CAN_SETTINGS_ERROR,
  CHANGE_COLOR,
  ACTIVATE_LEVER,
  DEACTIVATE_LEVER,
  ACTIVATE_LEVER_BASE,
  DEACTIVATE_LEVER_BASE,
  ACTIVATE_LEVER_HAND_REST,
  DEACTIVATE_LEVER_HAND_REST,
  INIT_MIRROR_LEVER,
  MIRROR_LEVER,
  CANCEL_RESET_MIRROR,
  INIT_RESET_LEVER,
  RESET_LEVER,
  CANCEL_RESET_LEVER,
  SET_LEVER_TYPE,
  ACTIVATE_HEAT,
  DEACTIVATE_HEAT,
  ACTIVATE_HAPTIC_FEEDBACK,
  DEACTIVATE_HAPTIC_FEEDBACK,
} from '../action-types';
import { debug } from '../helpers/debug';
import * as StringHelpers from '../helpers/string';
import * as Utils from '../utils';

import * as NotificationActions from './notifications';
import * as SelectionActions from './selections';
import * as PositionActions from './positions';

const validators = {
  length: (length) => {
    if (parseInt(length, 10) > 1000) {
      return StringHelpers.format(
        global.gettext("Cable can't be longer than %1"),
        '1000 mm',
      );
    } else if (parseInt(length, 10) < 200) {
      return StringHelpers.format(
        global.gettext("Cable can't be shorter than %1"),
        '200 mm',
      );
    }

    return '';
  },
};

/* -Loading and receiving levers
-----------------------------------------------------------------------------*/

function loadLevers() {
  return {
    type: LOAD_LEVERS,
  };
}

export function setLeverType(leverType) {
  console.log('=== ACTION: setLeverType ===');
  console.log('Setting lever type to:', leverType);
  return {
    type: SET_LEVER_TYPE,
    leverType,
  };
}

function loadLeverCANSettings() {
  return {
    type: LOAD_LEVER_CAN_SETTINGS,
  };
}

function receiveLevers(json) {
  return {
    type: RECEIVE_LEVERS,
    levers: json,
  };
}

function receiveLever(json) {
  console.log('=== ACTION: receiveLever ===');
  console.log('Received lever data:', json);
  return {
    type: RECEIVE_LEVER,
    lever: json,
  };
}

function receiveLeverCANSettings(json) {
  return {
    type: RECEIVE_LEVER_CAN_SETTINGS,
    settings: json,
  };
}

function receiveLeversError(error) {
  return {
    type: RECEIVE_LEVERS_ERROR,
    error,
  };
}

function receiveLeverCANSettingsError(error) {
  return {
    type: RECEIVE_LEVER_CAN_SETTINGS_ERROR,
    error,
  };
}

/**
 * Calculate positions after components has been received
 *
 * @param {Object} json - API response.
 * @return {Function}
 */
function afterRequestLevers(json) {
  return (dispatch) => {
    dispatch(receiveLevers(json));
    dispatch(PositionActions.calculatePositions());
  };
}

/**
 * Request levers.
 *
 * @param {boolean} [showLoadingState] - Dispatch action to set loading state.
 *   Defaults to true, set to false for a 'silent refresh'.
 * @return {Function} - Thunk
 */
export function requestLevers(showLoadingState = true) {
  return (dispatch) => {
    if (showLoadingState) {
      dispatch(loadLevers());
    }

    return Utils.ajaxCall(
      `/api/${global.AppData.uid}/levers/`,
      'GET',
      null,
      (response) => {
        console.log('Raw API response:', response);
        const result = afterRequestLevers(response);
        console.log('After processing:', result);
        return result;
      },
      receiveLeversError,
    );
  };
}

/**
 * Request available lever CAN settings.
 *
 * @return {Function} - Thunk
 */
export function requestLeverCANSettings() {
  return (dispatch) => {
    dispatch(loadLeverCANSettings());

    return Utils.ajaxCall(
      `/api/${global.AppData.uid}/lever-can-settings/`,
      'GET',
      null,
      receiveLeverCANSettings,
      receiveLeverCANSettingsError,
    );
  };
}

/**
 * General ajax call to put data to lever
 *
 * @param {number} leverId - Lever id/pk
 * @param {Object} body - Put body data
 * @param {Function} [successCallback] - Custom callback for success.
 * @param {Function} [errorCallback] - Custom callback for error.
 * @return {Promise}
 */
function putData(
  leverId,
  body,
  successCallback = receiveLever,
  errorCallback = receiveLeversError,
) {
  return Utils.ajaxCall(
    `/api/${global.AppData.uid}/levers/${leverId}/`,
    'PUT',
    body,
    successCallback,
    errorCallback,
  );
}
const debouncedPutData = debounce(putData, 500);

/* -Cable out
-----------------------------------------------------------------------------*/

function changeCableOutLength(leverId, length, error) {
  return {
    type: CHANGE_CABLE_OUT_LENGTH,
    id: leverId,
    length,
    error,
  };
}

/**
 * Put cable out length
 *
 * @param {number} leverId - Lever id
 * @param {number} length - New cable length
 * @return {Function} - Thunk
 */
export function putCableOutLength(leverId, length) {
  // Validate length
  const errorText = validators.length(length);

  return (dispatch) => {
    dispatch(changeCableOutLength(leverId, length, errorText));

    // Don't allow API call if length didn't validate
    if (errorText) {
      return;
    }

    debouncedPutData(leverId, {
      cable_length: length,
    });
  };
}

/* -Label
-----------------------------------------------------------------------------*/

function changeLabel(leverId, label) {
  return {
    type: CHANGE_LABEL,
    id: leverId,
    label,
  };
}

/**
 * Put label
 *
 * @param {number} leverId - Lever id
 * @param {string} label - New label
 * @return {Function} - Thunk
 */
export function putLabel(leverId, label) {
  return (dispatch) => {
    dispatch(changeLabel(leverId, label));

    return putData(leverId, {
      label,
    });
  };
}

/* -Lever CAN settings
-----------------------------------------------------------------------------*/

function changeLeverCANSettings(leverId, settings) {
  return {
    type: CHANGE_LEVER_CAN_SETTINGS,
    id: leverId,
    settings,
  };
}

/**
 * Put lever CAN settings.
 *
 * @param {number} leverId - Lever id.
 * @param {Object} settings - New setting(s).
 * @return {Function} - Thunk
 */
function putLeverCANSetting(leverId, settings) {
  return (dispatch, getState) => {
    const originalSettings = Utils.getById(getState().levers.entities, leverId)
      .can_settings;
    const newSettings = Utils.assign(originalSettings, settings);
    dispatch(changeLeverCANSettings(leverId, newSettings));

    function errorCallback(err) {
      return () => {
        debug('Lever CAN update error', err);
        dispatch(
          NotificationActions.addNotification({
            message: 'Could not update setting',
          }),
        );
        // Dispatch default error action
        dispatch(receiveLeversError(err));
        // Restore previous value
        dispatch(changeLeverCANSettings(leverId, originalSettings));
      };
    }

    return putData(
      leverId,
      {
        can_settings: newSettings,
      },
      undefined,
      errorCallback,
    );
  };
}

export function putBusTermination(leverId, value) {
  return putLeverCANSetting(leverId, { bus_termination: value });
}

export function putSourceAddress(leverId, value) {
  return putLeverCANSetting(leverId, { source_address: value });
}

export function putBaseSourceAddress(leverId, value) {
  return putLeverCANSetting(leverId, { source_address_base: value });
}

/* -Lever type
-----------------------------------------------------------------------------*/

function changeLeverType(leverId, leverType) {
  return {
    type: SET_LEVER_TYPE,
    id: leverId,
    leverType,
  };
}

export function putLeverType(leverId, leverType) {
  return (dispatch) => {
    console.log(`=== PUT LEVER TYPE START ===`);
    console.log(`Updating lever ${leverId} to type ${leverType}`);

    return Utils.ajaxCall(
      `/api/${global.AppData.uid}/levers/${leverId}/`,
      'PUT',
      {
        lever_type: leverType,
        uid: global.AppData.uid
      },
      (response) => {
        console.log('Received response from putLeverType:', response);
        dispatch(receiveLever(response));
        dispatch(setLeverType(leverType));
        return response;
      },
      (error) => {
        console.error('Error in putLeverType:', error);
        dispatch(receiveLeversError(error));
        throw error;
      }
    );
  };
}

/* -Accessory
-----------------------------------------------------------------------------*/

/**
 * Get current lever accessories
 * Used to get fresh data after reducer mutates state
 *
 * @param {number} leverId - Lever id
 * @param {Function} getState - Redux getState.
 */
function getLeverAccessories(leverId, getState) {
  return Utils.getById(getState().levers.entities, leverId).accessories;
}

function addAccessory(leverId, id) {
  return {
    type: ADD_ACCESSORY,
    id: leverId,
    accessoryId: id,
  };
}

function removeAccessory(leverId, id) {
  return {
    type: REMOVE_ACCESSORY,
    id: leverId,
    accessoryId: id,
  };
}

function clearAccessories(leverId) {
  return {
    type: CLEAR_ACCESSORIES,
    id: leverId,
  };
}

/**
 * Put accessory
 *
 * @param {number} leverId - Lever id
 * @param {number} id - Chosen accessory id
 * @return {Function} - Thunk
 */
export function putAccessory(leverId, id) {
  return (dispatch, getState) => {
    dispatch(addAccessory(leverId, id));

    function errorCallback(err) {
      return () => {
        // Remove the added accessory on error
        dispatch(removeAccessory(leverId, id));
        // Dispatch default error action
        dispatch(receiveLeversError(err));
      };
    }

    return putData(
      leverId,
      {
        accessories: getLeverAccessories(leverId, getState),
      },
      undefined,
      errorCallback,
    );
  };
}

/**
 * Delete accessory
 *
 * @param {number} leverId - Lever id
 * @param {number} id - Accessory id to remove
 * @return {Function} - Thunk
 */
export function deleteAccessory(leverId, id) {
  return (dispatch, getState) => {
    dispatch(removeAccessory(leverId, id));

    function errorCallback(err) {
      return () => {
        // Restore the removed accessory on error
        dispatch(addAccessory(leverId, id));
        // Dispatch default error action
        dispatch(receiveLeversError(err));
      };
    }

    return putData(
      leverId,
      {
        accessories: getLeverAccessories(leverId, getState),
      },
      undefined,
      errorCallback,
    );
  };
}

/* -Color
-----------------------------------------------------------------------------*/

function changeColor(color) {
  return {
    type: CHANGE_COLOR,
    color,
  };
}

/**
 * Changes color for all levers
 *
 * @param {Array} levers - All levers
 * @param {string} color - The new color
 * @return {Function} - Thunk
 */
export function putColor(levers, color) {
  return (dispatch) => {
    dispatch(changeColor(color));

    levers.map((lever) =>
      putData(lever.pk, {
        color,
      }),
    );
  };
}

/* -Activate/deactivate
-----------------------------------------------------------------------------*/

function activateLever(leverId) {
  return {
    type: ACTIVATE_LEVER,
    id: leverId,
  };
}

/**
 * Initiate lever activation
 *
 * @param {number} leverId - The lever id/pk that we're activating
 * @return {Function} - Thunk
 */
export function initiateLeverActivation(leverId) {
  return (dispatch) => {
    dispatch(activateLever(leverId));

    return Utils.ajaxCall(
      `/api/${global.AppData.uid}/levers/${leverId}/`,
      'PUT',
      {
        is_active: true,
      },
      null,
      receiveLeversError,
    );
  };
}

function deactivateLever(leverId) {
  return {
    type: DEACTIVATE_LEVER,
    id: leverId,
  };
}

/**
 * Show error and reset state on lever deactivation error.
 *
 * @param {Error} error - Error from API.
 * @param {number} leverId - Lever ID/pk.
 * @return {Function}
 */
export function leverDeactivationError(error, leverId) {
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext("The grip couldn't be removed"),
      }),
    );
    dispatch(activateLever(leverId));
  };
}

/**
 * Initiate lever deactivation
 *
 * @param {number} leverId - The lever id/pk that we're deactivating
 * @return {Function} - Thunk
 */
export function initiateLeverDeactivation(leverId) {
  return (dispatch) => {
    dispatch(deactivateLever(leverId));

    return Utils.ajaxCall(
      `/api/${global.AppData.uid}/levers/${leverId}/`,
      'PUT',
      {
        is_active: false,
      },
      null,
      leverDeactivationError,
      leverId,
    );
  };
}

/* -Add/remove base
-----------------------------------------------------------------------------*/

function activateLeverBase(leverId) {
  return {
    type: ACTIVATE_LEVER_BASE,
    id: leverId,
  };
}

/**
 * Initiate lever activation
 *
 * @param {number} leverId - The lever id/pk that we're activating
 * @return {Function} - Thunk
 */
export function initiateLeverBaseActivation(leverId) {
  return (dispatch) => {
    dispatch(activateLeverBase(leverId));

    function successCallback(json) {
      return () => {
        dispatch(clearAccessories(leverId));
        // Run default action
        dispatch(receiveLever(json));
      };
    }

    return putData(
      leverId,
      {
        has_base: true,
      },
      successCallback,
    );
  };
}

function deactivateLeverBase(leverId) {
  return {
    type: DEACTIVATE_LEVER_BASE,
    id: leverId,
  };
}

/**
 * Show error and reset state on lever deactivation error.
 *
 * @param {Error} error - Error from API.
 * @param {number} leverId - Lever ID/pk.
 * @return {Function}
 */
export function leverBaseDeactivationError(error, leverId) {
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext('Could not remove the base'),
      }),
    );
    dispatch(activateLeverBase(leverId));
  };
}

/**
 * Initiate lever deactivation
 *
 * @param {number} leverId - The lever id/pk that we're deactivating
 * @return {Function} - Thunk
 */
export function initiateLeverBaseDeactivation(leverId) {
  return (dispatch) => {
    dispatch(deactivateLeverBase(leverId));

    return putData(
      leverId,
      {
        has_base: false,
      },
      undefined,
      leverBaseDeactivationError,
    );
  };
}

/* -Add/remove hand-rest
-----------------------------------------------------------------------------*/

function activateLeverHandRest(leverId) {
  return {
    type: ACTIVATE_LEVER_HAND_REST,
    id: leverId,
  };
}

/**
 * Initiate lever activation
 *
 * @param {number} leverId - The lever id/pk that we're activating
 * @return {Function} - Thunk
 */
export function initiateLeverHandRestActivation(leverId) {
  return (dispatch) => {
    dispatch(activateLeverHandRest(leverId));

    return putData(leverId, {
      has_hand_rest: true,
    });
  };
}

function deactivateLeverHandRest(leverId) {
  return {
    type: DEACTIVATE_LEVER_HAND_REST,
    id: leverId,
  };
}

/**
 * Show error and reset state on lever deactivation error.
 *
 * @param {Error} error - Error from API.
 * @param {number} leverId - Lever ID/pk.
 * @return {Function}
 */
export function leverHandRestDeactivationError(error, leverId) {
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext('Could not remove the hand-rest'),
      }),
    );
    dispatch(activateLeverHandRest(leverId));
  };
}

/**
 * Initiate lever deactivation
 *
 * @param {number} leverId - The lever id/pk that we're deactivating
 * @return {Function} - Thunk
 */
export function initiateLeverHandRestDeactivation(leverId) {
  return (dispatch) => {
    dispatch(deactivateLeverHandRest(leverId));

    return putData(
      leverId,
      {
        has_hand_rest: false,
      },
      undefined,
      leverHandRestDeactivationError,
    );
  };
}

/* -Mirror
-----------------------------------------------------------------------------*/

export function mirrorLever(leverId) {
  return {
    type: INIT_MIRROR_LEVER,
    id: leverId,
  };
}

function confirmMirrorLever(leverId) {
  return {
    type: MIRROR_LEVER,
    id: leverId,
  };
}

/**
 * Reset mirroring
 */
export function cancelMirrorLever() {
  return {
    type: CANCEL_RESET_MIRROR,
  };
}

/**
 * Lever mirroring error; show error and reset state,
 *
 * @param {string} error - The error message.
 * @return {Function}
 */
export function leverMirroringError(error) {
  debug(error);
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext("The grip couldn't be mirrored"),
      }),
    );
    dispatch(cancelMirrorLever());
  };
}

/**
 * Lever mirrored successfully
 *
 * Show success notification and re-fetch data.
 *
 * @return {Function} - Thunk
 */
function leverMirrorDone() {
  return (dispatch) => {
    // Add success notification
    dispatch(
      NotificationActions.addNotification({
        type: 'success',
        message: global.gettext('The grip was mirrored successfully'),
      }),
    );
    // Re-fetch levers and selections before resetting mirroring state
    Promise.all([
      dispatch(requestLevers()),
      dispatch(SelectionActions.requestSelections()),
    ]).then(() => dispatch(cancelMirrorLever()));
  };
}

/**
 * Initiate mirroring from one lever to another.
 *
 * Dispatches confirmMirrorLever and calls clone api endpoint.
 *
 * @param {number} leverId - The lever ID/pk that we're mirroring from.
 * @return {Function} - Thunk
 */
export function initiateLeverMirroring(leverId) {
  return (dispatch) => {
    dispatch(confirmMirrorLever(leverId));

    return Utils.ajaxCall(
      `/api/${global.AppData.uid}/levers/${leverId}/clone`,
      'GET',
      null,
      leverMirrorDone,
      leverMirroringError,
    );
  };
}

/* -Reset
-----------------------------------------------------------------------------*/

export function resetLever(leverId) {
  return {
    type: INIT_RESET_LEVER,
    id: leverId,
  };
}

export function confirmResetLever(leverId) {
  return {
    type: RESET_LEVER,
    id: leverId,
  };
}

/**
 * Cancel the reset
 *
 * @param {number} leverId - Lever ID/pk.
 */
export function cancelResetLever(leverId) {
  return {
    type: CANCEL_RESET_LEVER,
    id: leverId,
  };
}

/**
 * Lever reset successful.
 *
 * Add success notification and re-fetch data.
 *
 * @param {Object} data - ?
 * @param {number} leverId - Lever ID/pk.
 * @return {Function} - Thunk
 */
function leverResetDone(data, leverId) {
  return (dispatch) => {
    // Add success notification
    dispatch(
      NotificationActions.addNotification({
        type: 'success',
        message: global.gettext('The grip was reset successfully'),
      }),
    );
    // Re-fetch lever and selections before resetting mirroring state
    Promise.all([
      dispatch(requestLevers()),
      dispatch(SelectionActions.requestSelections()),
    ]).then(() => dispatch(cancelResetLever(leverId)));
  };
}

/**
 * Initiate lever reset
 *
 * @param {number} leverId - The lever ID/pk that is being reset.
 */
export function initiateLeverReset(leverId) {
  return (dispatch) => {
    dispatch(confirmResetLever(leverId));

    return Utils.ajaxCall(
      `/api/${global.AppData.uid}/levers/${leverId}/reset`,
      'GET',
      null,
      leverResetDone,
      receiveLeversError,
      leverId,
    );
  };
}

/* -Heat toggle
-----------------------------------------------------------------------------*/

export function activateHeat(leverId, activationType) {
  return {
    type: ACTIVATE_HEAT,
    id: leverId,
    activationType,
  };
}
export function deactivateHeat(leverId) {
  return {
    type: DEACTIVATE_HEAT,
    id: leverId,
  };
}


export function heatActivationError(error, leverId) {
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext('Could not activate heat'),
      }),
    );
    dispatch(deactivateHeat(leverId));
  };
}

export function heatDeactivationError(error, leverId) {
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext('Could not deactivate heat'),
      }),
    );
    dispatch(activateHeat(leverId));
  };
}


export function initiateHeatActivation(leverId, activationType) {
  return (dispatch) => {
    dispatch(activateHeat(leverId, activationType));

    return putData(
      leverId,
      {
        has_heat: activationType, // Now sending 'CAN' or 'BUTTON'
      },
      undefined,
      heatActivationError,
    );
  };
}

export function initiateHeatDeactivation(leverId) {
  return (dispatch) => {
    dispatch(deactivateHeat(leverId));

    return putData(
      leverId,
      {
        has_heat: 'NONE', // Set to 'NONE' when deactivating
      },
      undefined,
      heatDeactivationError,
    );
  };
}

/* -Haptic feedback toggle
-----------------------------------------------------------------------------*/

function activateHapticFeedback(leverId) {
  return {
    type: ACTIVATE_HAPTIC_FEEDBACK,
    id: leverId,
  };
}

function deactivateHapticFeedback(leverId) {
  return {
    type: DEACTIVATE_HAPTIC_FEEDBACK,
    id: leverId,
  };
}

export function hapticFeedbackDeactivationError(error, leverId) {
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext('Could not deactivate haptic feedback'),
      }),
    );
    dispatch(activateHapticFeedback(leverId));
  };
}

export function hapticFeedbackActivationError(error, leverId) {
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext('Could not activate haptic feedback'),
      }),
    );
    dispatch(deactivateHapticFeedback(leverId));
  };
}

export function initiateHapticFeedbackActivation(leverId) {
  return (dispatch) => {
    dispatch(activateHapticFeedback(leverId));

    return putData(
      leverId,
      {
        has_haptic_feedback: true,
      },
      undefined,
      hapticFeedbackActivationError,
    );
  };
}

export function initiateHapticFeedbackDeactivation(leverId) {
  return (dispatch) => {
    dispatch(deactivateHapticFeedback(leverId));

    return putData(
      leverId,
      {
        has_haptic_feedback: false,
      },
      undefined,
      hapticFeedbackDeactivationError,
    );
  };
}
