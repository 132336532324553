import React from 'react';
import PropTypes from 'prop-types';

import * as constants from '../constants';
import * as utils from '../utils';


/**
 * Lever grip label display component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.lever - Lever object.
 * @return {Object} React element.
 */
export default function GripLabel({ lever }) {
  if (lever.label === constants.BLANK) {
    return <div />;
  }

  const labelName = constants.LABEL_STRING[lever.label];
  const labelSide = constants.GRIP_STRING[lever.grip];

  const className = `grip-label${lever.lever_type === 'A9' ? '-a9' : ''}`;

  return (
    <div className={className} role="presentation">
      <img src={utils.staticUrl(`img/${lever.lever_type === 'A9' ? 'A9-' : ''}label-${labelSide}-${labelName}.png`)} alt="" />
    </div>
  );
}
GripLabel.displayName = 'components/GripLabel';
GripLabel.propTypes = {
  lever: PropTypes.object.isRequired,
};
