import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { GRAY, BLACK, COLOR_STRING, BLANK } from '../constants';

import StaticImage from './StaticImage';

/**
 * Grip image.
 *
 * @param {Object} props - Which image size to use.
 * @param {string} props.color - Grip color, accepts constant or 'friendly'
 *   string.
 * @param {boolean} props.is_front - If viewing the front side of the grip.
 * @param {string} [props.variant] - Alternate variant to use.
 * @param {boolean} [props.useJpeg] - Use JPEG instead of PNG.
 * @param {string} [props.leverType] - The lever type.
 */
export default function GripImage(props) {
  const {
    variant,
    is_front,
    useJpeg,
    className,
    leverType,
    ...passProps
  } = props;
  const frontBack = is_front ? 'front' : 'back';
  const extension = useJpeg ? 'jpg' : 'png';
  const color =
    props.color.length === 2 ? COLOR_STRING[props.color] : props.color;
  const cls = classnames('grip-image', className);
  const variantPart = variant ? `-${variant}` : '';

  // const imageName = leverType
  //   ? `${leverType}-grip-${frontBack}-${color}${variantPart}.${extension}`
  //   : `grip-${frontBack}-${color}${variantPart}.${extension}`;


  const imageName = leverType
    ? `${leverType}-grip-${frontBack}-${color}${variantPart}.${extension}`
    : `grip-${frontBack}-right.${extension}`;
  // console.log('leverType in GripImage.js: ', leverType);
  // console.log('imageName in GripImage.js: ', imageName);

  return <StaticImage {...passProps} className={cls} name={imageName} />;
}
GripImage.displayName = 'components/GripImage';
GripImage.propTypes = {
  color: PropTypes.oneOf([
    GRAY,
    BLACK,
    BLANK,
    COLOR_STRING[GRAY],
    COLOR_STRING[BLACK],
  ]).isRequired,
  className: PropTypes.string,
  is_front: PropTypes.bool.isRequired,
  useJpeg: PropTypes.bool,
  variant: PropTypes.oneOf(['thumbnail']),
  leverType: PropTypes.string,
};
GripImage.defaultProps = {
  className: '',
  useJpeg: false,
  variant: undefined,
  leverType: undefined,
};
