/**
 * Positions and selections grid helpers.
 *
 * @module helpers/grid
 */

import unionWith from 'lodash/unionWith';

import * as constants from '../constants';
import * as Utils from '../utils';

/**
 * Front grid X and Y size.
 *
 * @type {Object}
 */
export const GRID_FRONT = { x: 6, y: 4 };
export const GRID_FRONT_A9 = { x: 6, y: 4 };

/**
 * Back grid X and Y size.
 *
 * @type {Object}
 */
export const GRID_BACK = { x: 4, y: 8 };
export const GRID_BACK_A9 = { x: 4, y: 8 };
/**
 * Grid resolution.
 *
 * @type {Number}
 */
export const GRID_RESOLUTION = 2;
export const GRID_RESOLUTION_A9 = 2;

/**
 * Check if two position objects are equal.
 *
 * @param {GridPosition} posA - The first position object.
 * @param {GridPosition} posB - The second position object.
 * @return {boolean}
 */
export function isEqualPosition(posA, posB) {
  const isEqualPos = posA.x === posB.x && posA.y === posB.y;
  let isEqualSize = true;

  // Also compare sizes if passed
  if (posA.x_size && posB.x_size) {
    isEqualSize = posA.x_size === posB.x_size && posA.y_size === posB.y_size;
  }

  return isEqualPos && isEqualSize;
}

/**
 * Get X and Y sizes by direction.
 *
 * Default direction is vertical, X size and Y size gets flipped when
 * horizontal.
 *
 * @param {string} x_size - X size
 * @param {string} y_size - Y size
 * @param {string} direction - Direction
 * @return {{x_size: string, y_size: string}}
 */
export function getXYSizeByDirection(x_size, y_size, direction) {
  switch (direction) {
    case constants.HORIZONTAL:
      return { x_size: y_size, y_size: x_size };

    // Vertical is the default direction
    case constants.VERTICAL:
    case constants.NOT_APPLICABLE:
    default:
      return { x_size, y_size };
  }
}

/**
 * Get a mirrored horizontal position.
 *
 * @param {GridPosition} position - Position object. The `x_size` and `y_size`
 *   properties are included on the resulting object if passed on the original
 *   one. If missing, the X size defaults to 1, but isnt' included.
 * @param {boolean} is_front - If mirroring on the front grip.
 * @return {GridPosition}
 */
export function getMirroredPosition(position, is_front) {
  const grid = is_front ? GRID_FRONT : GRID_BACK;
  const xSize = position.x_size ? position.x_size : 1;
  const ySize = position.y_size ? position.y_size : 1;

  const mirrored = {
    x: grid.x - position.x - xSize,
    y: position.y,
  };

  // Include size if it exists on the passed object
  if (position.x_size) {
    mirrored.x_size = xSize;
    mirrored.y_size = ySize;
  }

  return mirrored;
}

/**
 * Get an array of mirrored horizontal positions.
 *
 * @param {Array} list - {@link GridPosition} objects.
 * @param {boolean} is_front - If mirroring on the front grip.
 * @return {Array}
 */
export function getMirroredPositions(list, is_front) {
  return list.map((pos) => getMirroredPosition(pos, is_front));
}

/**
 * Get a mirrored horizontal position on the front grid.
 *
 * @param {GridPosition} position - Position object.
 * @see getMirroredPosition
 * @return {GridPosition}
 */
export function getMirroredFrontPosition(position) {
  return getMirroredPosition(position, true);
}

/**
 * Get a mirrored horizontal position on the back grid.
 *
 * @param {GridPosition} position - Position object.
 * @see getMirroredPosition
 * @return {GridPosition}
 */
export function getMirroredBackPosition(position) {
  return getMirroredPosition(position, false);
}

/**
 * Left back grid groups
 *
 * @type {{1: *[], 2: *[], 3: *[], 4: *[]}}
 */
const backGridGroupsLeft = {
  1: [{ x: 2, y: 0 }, { x: 3, y: 0 }, { x: 2, y: 1 }, { x: 3, y: 1 }],
  2: [
    { x: 0, y: 2 },
    { x: 1, y: 2 },
    { x: 2, y: 2 },
    { x: 3, y: 2 },
    { x: 0, y: 3 },
    { x: 1, y: 3 },
    { x: 2, y: 3 },
    { x: 3, y: 3 },
  ],
  3: [
    { x: 0, y: 4 },
    { x: 1, y: 4 },
    { x: 2, y: 4 },
    { x: 3, y: 4 },
    { x: 0, y: 5 },
    { x: 1, y: 5 },
    { x: 2, y: 5 },
    { x: 3, y: 5 },

  ],
  4: [{ x: 0, y: 6 }, { x: 1, y: 6 }, { x: 0, y: 7 }, { x: 1, y: 7 }],
};

const backGridGroupsLeftA9 = {
  1: [{ x: 2, y: 0 }, { x: 3, y: 0 }, { x: 2, y: 1 }, { x: 3, y: 1 }],
  2: [
    { x: 0, y: 2 },
    { x: 1, y: 2 },
    { x: 2, y: 2 },
    { x: 3, y: 2 },
    { x: 0, y: 3 },
    { x: 1, y: 3 },
    { x: 2, y: 3 },
    { x: 3, y: 3 },
  ],
  3: [
    { x: 0, y: 4 },
    { x: 1, y: 4 },
    { x: 0, y: 5 },
    { x: 1, y: 5 },
    { x: 0, y: 6 },
    { x: 1, y: 6 },
    { x: 0, y: 7 },
    { x: 1, y: 7 },
  ],
  4: [
    { x: 0, y: 6 },
    { x: 1, y: 6 },
    { x: 0, y: 7 },
    { x: 1, y: 7 },
  ],
};

// Mirror left back grid groups to right
const _backGridGroupsRight = {};
// eslint-disable-next-line no-restricted-syntax
for (const id in backGridGroupsLeft) {
  if (Object.hasOwnProperty.call(backGridGroupsLeft, id)) {
    const leftGroup = backGridGroupsLeft[id];
    const group = [];
    for (let i = 0, len = leftGroup.length; i < len; i += 1) {
      group.push(getMirroredBackPosition(leftGroup[i]));
    }
    _backGridGroupsRight[id] = group;
  }
}

const _backGridGroupsRightA9 = {};

for (const id in backGridGroupsLeftA9) {
  if (Object.hasOwnProperty.call(backGridGroupsLeftA9, id)) {
    const leftGroup = backGridGroupsLeftA9[id];
    const group = [];
    for (let i = 0, len = leftGroup.length; i < len; i += 1) {
      group.push(getMirroredBackPosition(leftGroup[i]));
    }
    _backGridGroupsRightA9[id] = group;
  }
}

/**
 * Right back grid groups.
 *
 * Mirrored version of the left groups.
 *
 * @type {{1: *[], 2: *[], 3: *[], 4: *[]}}
 */
const backGridGroupsRight = _backGridGroupsRight;
const backGridGroupsRightA9 = _backGridGroupsRightA9;
/**
 * Left back grid blacklist.
 *
 * @type {Array} {@link GridPosition} objects.
 */
const backBlacklistLeft = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },

  { x: 2, y: 4 },
  { x: 3, y: 4 },
  { x: 2, y: 5 },
  { x: 3, y: 5 },

  { x: 2, y: 6 },
  { x: 3, y: 6 },
  { x: 2, y: 7 },
  { x: 3, y: 7 },
];

const backBlacklistLeftA9 = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },

  // { x: 2, y: 4 },
  // { x: 3, y: 4 },
  // { x: 2, y: 5 },
  // { x: 3, y: 5 },

  { x: 2, y: 6 },
  { x: 3, y: 6 },
  { x: 2, y: 7 },
  { x: 3, y: 7 },
];

// Mirror left back blacklist to right
const _backBlacklistRight = [];
for (let i = 0, len = backBlacklistLeft.length; i < len; i += 1) {
  _backBlacklistRight.push(getMirroredBackPosition(backBlacklistLeft[i]));
}

const _backBlacklistRightA9 = [];
for (let i = 0, len = backBlacklistLeftA9.length; i < len; i += 1) {
  _backBlacklistRightA9.push(getMirroredBackPosition(backBlacklistLeftA9[i]));
}

/**
 * Right back grid blacklist.
 *
 * Mirrored version of the left blacklist.
 *
 * @type {Array} {@link GridPosition} objects.
 */
const backBlacklistRight = _backBlacklistRight;
const backBlacklistRightA9 = _backBlacklistRightA9;
/**
 * Get a back grid blacklist, optionally merged with additional positions.
 *
 * @param {string} lever - Left or right lever. Accepts constant (`LT`) or
 *   'grip string' (`left`).
 * @param {Array} additionalPositions - Additional positions to merge into the
 *   blacklist. Must be {@link GridPosition} objects.
 * @param {boolean} is_a9 - If the lever is an A9 lever.
 * @return {Array} {@link GridPosition} objects.
 */
export function getBackBlacklist(lever, additionalPositions = [], is_a9 = false) {
  const isLeft = lever === constants.LEFT || lever === 'left';
  let blacklist;
  if (isLeft) {
    blacklist = is_a9 ? backBlacklistLeftA9 : backBlacklistLeft;
  } else {
    blacklist = is_a9 ? backBlacklistRightA9 : backBlacklistRight;
  }

  return unionWith(blacklist, additionalPositions, isEqualPosition);
}

/**
 * Get all coordinates that a position occupies.
 *
 * @param {number} x - X position
 * @param {number} y - Y position
 * @param {number} [x_size] - X size
 * @param {number} [y_size] - Y size
 * @return {Array} {@link GridPosition} objects of occupied coordinates.
 */
export function getPositionCoordinates(x, y, x_size = 1, y_size = 1) {
  const coordinates = [];
  const xLen = x + x_size;
  const yLen = y + y_size;
  let yLoop = y;

  for (x; x < xLen; x += 1) {
    // Reset Y every iteration
    yLoop = y;
    for (yLoop; yLoop < yLen; yLoop += 1) {
      coordinates.push({ x, y: yLoop });
    }
  }

  return coordinates;
}

/**
 * Get back grid group that a position is placed in.
 *
 * Each position on the back grid requires different images and possibly some
 * layout adjustments, so the position must be uniquely identified.
 *
 * @param {number} x - X position
 * @param {number} y - Y position
 * @param {number} x_size - X size
 * @param {number} y_size - Y size
 * @param {string} grip - Which grip, `left` or `right`.
 * @param {boolean} is_a9 - If the lever is an A9 lever.
 * @return {string|Null} The back grid group ID.
 */
export function getBackGridGroup(x, y, x_size, y_size, grip, is_a9) {
  // Get all coordinates this position occupies
  const cells = getPositionCoordinates(x, y, x_size, y_size);
  const isLeft = grip === constants.LEFT || grip === 'left';
  let gridGroups;
  // Select grid groups based on lever type
  if (is_a9) {
    gridGroups = isLeft ? backGridGroupsLeftA9 : backGridGroupsLeftA9;
  } else {
    gridGroups = isLeft ? backGridGroupsLeft : backGridGroupsRight;
  }

  // Check each cell against each group's cells
  return Object.entries(gridGroups).reduce((result, [id, groupCells]) => {
    // If we already found a match, skip checking other groups
    if (result) return result;

    // Check if any of this position's cells overlap with group cells
    const hasMatch = cells.some((cell) =>
      groupCells.some((groupCell) =>
        groupCell.x === cell.x && groupCell.y === cell.y,
      ),
    );

    return hasMatch ? id : result;
  }, null);
}

/**
 * Check if a position exists in a list of positions.
 *
 * @param {GridPosition} position - The position object to search for.
 * @param {Array} list - List of {@link GridPosition} objects.
 * @return {boolean}
 */
export function isCoordinateInList(position, list) {
  for (let i = 0, len = list.length; i < len; i += 1) {
    if (isEqualPosition(position, list[i])) {
      return true;
    }
  }

  return false;
}

/**
 * Check if a position exists in a list of positions.
 *
 * @param {GridPosition} position - The position object to search for.
 * @param {Array} list - List of {@link GridPosition} objects.
 * @return {boolean}
 */
export function isPositionInList(position, list) {
  const { x, y, x_size, y_size } = position;
  const positionCoordinates = getPositionCoordinates(x, y, x_size, y_size);

  for (let i = 0, len = positionCoordinates.length; i < len; i += 1) {
    if (isCoordinateInList(positionCoordinates[i], list)) {
      return true;
    }
  }

  return false;
}
